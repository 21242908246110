import '../../assets/scss/AppLayout.scss';

import Footer from './Footer';
import { Layout } from 'antd';
import NavBar from "./NavBar";
import { Outlet } from 'react-router-dom';

export default function AppLayout() {
    return (
        <Layout className="layoutTop">
            <NavBar />
            <Layout className='appLayout'>
                <Layout className={`mainContent`}>
                    <div className={`mainContentWrapper`}>
                        <Outlet />
                    </div>
                    <Footer />
                </Layout>
            </Layout>
        </Layout>
    )
}