export const filterFaq = (data: any[], search: string) => {
    if (!data) return [];
    return data.filter((n: any) => {
        if (search === '') return n;
        else if (n?.question.toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.answer.toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.tags.join(',').toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.category.toLowerCase().includes(search.toLowerCase())) return n;
        //else if (n?.environment.join(',').toLowerCase().includes(search.toLowerCase())) return n;
        else return null;
    });
}
