import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Tag } from 'antd';

const StatusTag = (props: any) => {
    const { status } = props;

    switch (status) {
      case 'UP':
      case 'GREEN':
        return <Tag color="success"><CheckCircleOutlined /> Available</Tag>;

      case 'warning':
      case 'YELLOW':
        return <Tag color="warning"><ExclamationCircleOutlined /> Incident</Tag>;

      case 'error':
      case 'RED':
      case 'OUT_OF_SERVICE':
      case 'DOWN':
        return <Tag color="error"><CloseCircleOutlined /> Error</Tag>;

      default:
        return '';
    }
  };

  export default StatusTag;