import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useNews(newsId: string = '') {
    const [news, setNews] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);


    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                let content: any;
                content = await callBackEnd('portal', 'GET', `content/news`);

                let newsToReturn: any = [];
                if (newsId !== '') {
                    newsToReturn = content.filter((item: any) => item.id === Number(newsId));
                    if (newsToReturn !== undefined && newsToReturn.length > 0) {
                        newsToReturn = newsToReturn[0];
                    }
                    else{
                        newsToReturn = null;
                    }
                }
                else{
                    newsToReturn = content;
                }
                setNews(newsToReturn);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getData().catch((e: any) => { console.log(e) });
    }, [newsId]);

    return { news, loading, error };
}