import { useEffect, useState } from 'react';

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import Moment from 'react-moment';
import { Table } from "antd";

export default function TopExceptions(props: { topExceptions: Promise<any> }) {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await props.topExceptions;
                if (result.length > 0) {
                    setData(result);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.topExceptions]);


    const columns = [
        {
            title: 'Exception reference',
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a: any, b: any) => a.reference.localeCompare(b.reference),
        },
        {
            title:"Creation date",
            dataIndex: 'created',
            key: 'created',
            sorter: (a: any, b: any) => a.created - b.created,
            render: (text: any) => {
                return <Moment format="YYYY-MM-DD HH:mm:ss">{text}</Moment>
            }
        },
        {
            title: "Last update",
            dataIndex: 'updated',
            key: 'updated',
            sorter: (a: any, b: any) => a.updated - b.updated,
            render: (text: any) => {
                return <Moment format="YYYY-MM-DD HH:mm:ss">{text}</Moment>
            }
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: any, b: any) => a.count - b.count,
            defaultSortOrder: 'descend' as const
        }
    ];

    return loading ? <LoadingSkeleton /> : <Table size="small" dataSource={data} pagination={false} columns={columns} scroll={{ x: 'max-content' }} />;

}
