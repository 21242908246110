import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useReleaseNotes() {
    const [releaseNotes, setReleaseNotes] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [versions, setVersions] = useState<any[]>([]);

    useEffect(() => {
        async function getReleaseNotes() {
            try {
                setLoading(true);

                const getReleaseNotesUrl = async (url: string) => {
                    const response = await fetch(url);
                    const data = await response.text();
                    return data;
                }

                const versionsList: any = await callBackEnd('external', 'GET', 'https://neterium-portal-config.s3.eu-central-1.amazonaws.com/demo/releasenotes.json');
                const versions: any[] = Object.keys(versionsList['API Versions']);
                const allVersions: any[] = await Promise.all(versions.map(async (version: string) => {
                    return {
                        version: version,
                        content: await getReleaseNotesUrl(versionsList['API Versions'][version])
                    }
                }));
                setVersions(versions);
                setReleaseNotes(allVersions);
                setError(null);
            } catch (error: any) {
                console.log(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getReleaseNotes().catch((e: any) => console.log(e));

    }, []);

    return { releaseNotes, versions, loading, error };
}