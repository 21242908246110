import { Alert, Button, List, Tag, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import ShowError from '../Layout/ShowError';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import useFaq from '../../hooks/useFaq';

export const FaqWidget = () => {
    const { faq, loading, error } = useFaq()

    if (loading) {
        return (
            <Loader />
        )
    }
    return (
        <>
            {faq.length > 0 && !loading && error === null ?
                <WidgetCard
                    title="FAQ"
                    extra={<Link to='/faq'><Button>Read all</Button></Link>}
                    className="card faq-widget-card"
                >
                    {error ? (
                        <Alert
                            message="Error"
                            description={error.toString()}
                            type="error"
                            showIcon
                        />
                    ) : (
                        <List
                            itemLayout="vertical"
                            size="large"
                            pagination={{ pageSize: 6, align: 'center' }}
                            dataSource={faq}
                            loading={loading}
                            renderItem={(item: any) => (
                                <List.Item >
                                    <Link to={`/faq#q${item.id}`}><Typography.Text strong>{item.question}{' '}</Typography.Text></Link>
                                    {item.tags.map((tag: string) => (<Tag color="geekblue" key={tag}>{tag}</Tag>))}
                                </List.Item>
                            )}
                        />
                    )}
                </WidgetCard>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                </>
            }
        </>
    );
};