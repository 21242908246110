// utils.tsx
import { Tag } from 'antd'; // assuming you're using antd, import it here
import { getFormattedEnvironment } from '../../utils/getEnvironments';

export const showEnvPill = (env: string) => {
    if (env === 'all') return <span className={`envPill ${env}`}>All Environments</span>;
    const fomattedEnv: { name: string, label: string } | null = getFormattedEnvironment(env);
    if (!fomattedEnv) return null
    return (
        <Tag className={`envPill ${env}`}>{fomattedEnv.label}</Tag>
    )
}

export const filterNews = (news: any[], search: string) => {
    if (!news) return [];
    return news.filter((n: any) => {
        if (search === '') return n;
        else if (n?.title.toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.content.toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.environment.join(',').toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.tags.join(',').toLowerCase().includes(search.toLowerCase())) return n;
        else if (n?.category.toLowerCase().includes(search.toLowerCase())) return n;
        else return null;
    });
}


