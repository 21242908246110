export const getCollectionFromRecord = (record: any): string => {

    const prefixes: { name: string, prefixes: string[] }[] = [
        { name: 'Acuris', prefixes: ['ACU'] },
        { name: 'Dowjones', prefixes: ['DJ'] },
        { name: 'Kharon', prefixes: ['KR'] },
        { name: 'LPTS', prefixes: ['DJS'] },
        { name: 'Refinitiv', prefixes: ['RFN'] },
        { name: 'Swift', prefixes: ['SWF'] },
        { name: 'Sanctions', prefixes: ['OFAC', 'HMT', 'EU', 'UN'] },
        { name: 'Private', prefixes: ['PRV'] }
    ]


    if (record?.modelValidation?.profileId) {
        const collectionFromPrefix = prefixes.find(prefix => prefix.prefixes.some(prefix => record.modelValidation.profileId.startsWith(prefix)));
        return collectionFromPrefix?.name.toLowerCase() || '';

    }
    else {
        return '';
    }
}
