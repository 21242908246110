import { Button, Divider, List, Typography } from 'antd'

import React from 'react'

export default function PrivacyStatement() {
    return (
        <article>
            <Typography.Title level={1}>Privacy Statement</Typography.Title>
            <List header="Table of Contents" size='small' bordered style={{ backgroundColor: "white" }}>

                <List.Item><a href="#introduction">Introduction</a></List.Item>
                <List.Item><a href="#neteriumPurposes">NETERIUM purposes</a></List.Item>
                <List.Item><a href="#dataCollected">Data collected</a></List.Item>
                <List.Item><a href="#waysOfCOllectingYourData">Ways of collecting your data</a></List.Item>
                <List.Item><a href="#opertationAndImprovementOfOurWebsite">Operation and improvement of our website</a></List.Item>
                <List.Item><a href="#operationImprovementSafeguard">Operation, improvement and safeguard of our developer or user portal and infrastructures</a></List.Item>
                <List.Item><a href="#analysisOfEndUser">Analysis of end users’Marketing and operational communications and management of our relationships with our customers, partners, prospects and suppliers</a></List.Item>
                <List.Item><a href="#marketingAndCommunications">Marketing and operational communications and management of our relationships with our customers, partners, prospects and suppliers</a></List.Item>
                <List.Item><a href="#talentAndHr">Talent recruitment and HR management</a></List.Item>
                <List.Item><a href="#dataSecurity">Data Security</a></List.Item>
                <List.Item><a href="#dataSubmitted">Data submitted on behalf of someone else</a></List.Item>
                <List.Item><a href="#sharingData">Sharing data</a></List.Item>
                <List.Item><a href="#otherWebsites">Privacy policies of other websites</a></List.Item>
                <List.Item><a href="#rights">Your data protection rights</a></List.Item>
                <List.Item><a href="#contactingUs">Contacting us</a></List.Item>
                <List.Item><a href="#belgianAuthority">Belgian data protection authority</a></List.Item>

            </List>
            <Divider />
            <Typography.Title level={4} id="introduction">Introduction</Typography.Title>
            <Typography.Paragraph>Neterium srl <b>(“NETERIUM”)</b>, with registered office at 6 Chemin du Cyclotron, 1348 Louvain-La-Neuve, Belgium, is committed to protecting your privacy.</Typography.Paragraph>
            <Typography.Paragraph>In this Data Privacy Statement, the terms <b>“Controller”</b>, <b>“Processor”</b>, <b>“Personal Data”</b> and <b>“Processing”</b> shall have the meaning given to these terms in the EU General Data Protection Regulation (<b>“GDPR”</b>) (Regulation (EU) 2016/679) (<b>“EU Data Protection Law”</b>).</Typography.Paragraph>
            <Typography.Paragraph>This Data Privacy Statement explains how your Personal Data is processed by NETERIUM, as data Controller for the data Processing activities described in this Data Privacy Statement.</Typography.Paragraph>
            <Typography.Paragraph>It applies to data collected through our website or collected during interactions you may have with us (e.g. when you call us or send us emails, or use our developer or user portal or services). NETERIUM will process all your Personal Data in compliance with the EU General DataProtection Regulation (Regulation (EU) 2016/679).</Typography.Paragraph>
            <Typography.Paragraph>This Data Privacy Statement does not apply to any Personal Data Processing by NETERIUM when providing its Jetflow and Jetscan production services to its clients or partners. Such Personal Data Processing - where NETERIUM acts as data Processor - is covered through the specific Data Processing Agreements separately signed with each of our clients and partners.</Typography.Paragraph>
            <Typography.Paragraph>NETERIUM may modify this Data Privacy Statement from time to time. This Data Privacy Statement was last updated on 31 October 2022.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="neteriumPurposes">NETERIUM purposes</Typography.Title>
            <Typography.Paragraph>NETERIUM processes Personal Data collected on our website or through the interactions you have with us for the following purposes:</Typography.Paragraph>
            <ul style={{ listStyleType: "square", marginLeft: "2em" }}>
                <li>The operation and improvement of our website;</li>
                <li>The provision of NETERIUM services, including its developer or user portal;</li>
                <li>The analysis of end users’ usage data for product development and customer experience improvement;</li>
                <li>Sending commercial or operational communications, newsletters, new software release updates and other customer communications;</li>
                <li>The management of contacts and relationships with our customers, partners, prospects and suppliers; and</li>
                <li>Talent recruitment and HR management.</li>
            </ul>
            <Divider />
            <Typography.Title level={4} id="dataCollected">Data collected</Typography.Title>
            <Typography.Paragraph>For the above-mentioned purposes, NETERIUM will generally process the following data pertaining to you (depending on communication channel you use and type of activity): identification and contact information (e.g. last name, first name, job title, company name, contact details - such as mobile, landline, e-mail or post address), IP address, login and password, the history of your interactions with NETERIUM (e.g., connection logs, meetings), and any other messages (e.g. content of e-mails or web-forms) you would exchange with us.</Typography.Paragraph>
            <Typography.Paragraph>For recruitment and subsequent HR management, we may also process additional Personal Data such as date of birth, CV, Educational training, national id number, nationality, immigration status, marital status, employment history, diplomas and bank account number.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="waysOfCOllectingYourData">Ways of collecting your data</Typography.Title>
            <Typography.Paragraph>You directly provide NETERIUM with most of the data we collect. We collect data and process data when you:</Typography.Paragraph>
            <ul style={{ listStyleType: "square", marginLeft: "2em" }}>
                <li>Register to our developer or user portal;</li>
                <li>Place an order for or register to any of our products or services;</li>
                <li>Register to our operational notifications or newsletters;</li>
                <li>Communicate with us via e-mail, video conference or phone or through our website’s contact forms;</li>
                <li>Provide feedback on any of our products or services or complete a customer survey;</li>
                <li>Provide your Personal Data during the recruitment process; or</li>
                <li>View our website via your browser’s cookies.</li>
            </ul>
            <Typography.Paragraph>For recruitment and subsequent possible HR management, we may also indirectly receive Personal Data information from the external HR Management companies we may use.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="opertationAndImprovementOfOurWebsite">Operation and improvement of our website</Typography.Title>
            <Typography.Paragraph>NETERIUM has a legitimate interest to process your Personal Data for the operation and improvement of its website as detailed below:</Typography.Paragraph>

            <ul style={{ listStyleType: "square", marginLeft: "2em" }}>
                <li><i>IP addresses</i>. For our internal purposes, we may use IP addresses (the Internet address of your computer) stored in web logs to generate aggregate statistics on the usage of our website, such as navigation path, volumes or time spent on a page.</li>
                <li><i>Cookies</i>. Our website uses cookies. The information stored in cookies may include your name, first name, navigation behaviour, login ID, and IP addresses. In addition, our website uses Google Analytics. Our related Cookie Policy provides a detailed description on the use and purposes of cookies via NETERIUM’s website.</li>
            </ul>
            <Typography.Paragraph>Your Personal Data will be kept until the information is no longer necessary for the purposes for which we process it. This typically means no longer than three years after your last interaction with NETERIUM.</Typography.Paragraph>

            <Divider />
            <Typography.Title level={4} id="operationImprovementSafeguard">Operation, improvement and safeguard of our developer or user portal and infrastructures</Typography.Title>
            <Typography.Paragraph>When you submit your Personal Data through our developer or user portal or with regard to your company accessing our production environment, NETERIUM has a legitimate interest to process your Personal Data (for example, your personal identification data) to get in contact with you in order to obtain additional information or take specific actions related to possible vulnerabilities or malicious activity.</Typography.Paragraph>
            <Typography.Paragraph>Your Personal Data will be kept until the information is no longer necessary for the purposes for which we process it. This typically means no longer than three years after your last interaction with NETERIUM.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="analysisOfEndUser">Analysis of end users’ usage data for product development and customer experience improvement</Typography.Title>
            <Typography.Paragraph>NETERIUM has a legitimate interest to analyse and produce reports about the usage of its products and services for the following purposes:</Typography.Paragraph>
            <ul style={{ listStyleType: "square", textIndent: "1em", marginLeft: "2em" }}>
                <li>Create internal reporting about the individual or aggregated usage of the products, services, and use it to improve the quality of our products or services;</li>
                <li>Resolve product/service support cases that you may have;</li>
                <li>Provide specific training or awareness communication to our customers and partners;</li>
                <li>Produce aggregated statistics about the usage of our products, services or website.</li>
            </ul>
            <Typography.Paragraph>Your Personal Data will be kept until the information is no longer necessary for the purposes for which we process it. This typically means no longer than three years after your last interaction with NETERIUM.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="marketingAndCommunications">Marketing and operational communications and management of our relationships with our customers, partners, prospects and suppliers</Typography.Title>
            <Typography.Paragraph>NETERIUM has a legitimate interest to process Personal Data that you submit on our website or during your interactions with us (for example, subscription to our developer or user portal, purchase or use of our products), to provide you with commercial or operational communications related to NETERIUM products and services. In this respect, if you or your company are a NETERIUM customer or partner, we might send you communications related to the NETERIUM products or services that you use or purchased.</Typography.Paragraph>
            <Typography.Paragraph>From time to time, NETERIUM may send you invitations to participate in market researches or surveys in order to gather feedback on existing products and services or on future product developments. It is both NETERIUM’s and its customers’ legitimate interest to identify how to improve the quality of NETERIUM services and products through surveys, in compliance with the recipients’ preferences. If you do not wish to receive such surveys or market researches, you may choose to opt out from these.</Typography.Paragraph>

            <Typography.Paragraph>NETERIUM would also like to send you information about our products and services that we think you might like. If you have agreed to receive marketing communications, you may always opt out at a later date. You have the right at any time to stop NETERIUM from contacting you for marketing purposes.</Typography.Paragraph>
            <Typography.Paragraph>Your Personal Data will be kept until the information is no longer necessary for the purposes for which we process it. This typically means no longer than three years after your last interaction with NETERIUM. NETERIUM may however keep the information for a longer period of time if we are required by law or to defend our rights.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="talentAndHr">Talent recruitment and HR management</Typography.Title>

            <Typography.Paragraph>When you send us a spontaneous application or interact with our staff, you provide us with Personal Data in order to enter the recruitment process of NETERIUM. NETERIUM – as potential future employer - has the right to process your data based on the following legal basis: (i) necessity to enter into an employment contract, (ii) compliance with a legal obligation to which the employer is subject or (iii) where the employer or a third party has a legitimate interest in using the Personal Data (for example, to assess and evaluate job applicants before making an offer for employment).</Typography.Paragraph>
            <Typography.Paragraph>We do not require any sensitive data in our recruitment process. We therefore request you not to communicate any personal details revealing religious or philosophical beliefs, ethnic or racial origin, political opinions, genetic data, data concerning health or data concerning your sexual orientation. Such data will be ignored.</Typography.Paragraph>
            <Typography.Paragraph>We will process your data for the purposes of handling the recruitment process, assessing your application, and possibly hiring you. We will keep all your data confidential and act in full compliance with GPDR. We will not share your data with third parties outside NETERIUM, with the exception of (i) providers of HR management services, (ii) agents, advisors, and other third parties providing services to support our business operations, such as recruitment agencies, background screening agencies, and law firms, (iii) governmental, judicial, regulatory, and other bodies and authorities where required by applicable law.</Typography.Paragraph>
            <Typography.Paragraph>Your data will not be kept longer than required for the recruitment process needs unless NETERIUM needs to keep the data (for example, in case of confirmed employment) on the history of the employee (in which case, the data will be deleted as per legal retention period applicable to HR files, typically 5 years after the end of the employment contract).</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="dataSecurity">Data Security</Typography.Title>
            <Typography.Paragraph>We are committed to protecting your Personal Data against accidental or unlawful destruction, accidental loss, alteration, and unauthorised disclosure or access. We maintain tight controls over the Personal Data we collect.</Typography.Paragraph>
            <Typography.Paragraph>We also monitor and record the data exchange (IP address, timestamp, volumes), both incoming and outgoing, in order to preserve the security, integrity, and availability of our infrastructure and information/data. In addition, in case of suspicious activity, NETERIUM might collect data (including Personal Data) from various sources (for example, public sources, threat intelligence providers) in order to start and manage its own investigation. This data is kept for up to one year. Data can be kept longer when a security issue has been encountered and evidences need to be kept for NETERIUM to exercise its rights and remedies. Any Personal Data collected during this process may be shared by NETERIUM with the relevant authorities.</Typography.Paragraph>
            <Typography.Paragraph>We cannot ensure the security of your data on your computer and we advise you to take every possible precaution to protect Personal Data stored on your computer and transiting on the Internet.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="dataSubmitted">Data submitted on behalf of someone else</Typography.Title>
            <Typography.Paragraph>If you provide Personal Data of another person to NETERIUM for the NETERIUM purposes previously mentioned, you shall ensure that (i) this person has been duly informed about NETERIUM&apos;s right to process such Personal Data, and has been provided with this Data Privacy Statement, (ii) such Personal Data is collected and supplied in accordance with applicable legislation and without infringing such person&apos;s or any third party’s rights and (iii) you have obtained her/his prior consent where needed.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="sharingData">Sharing data</Typography.Title>
            <Typography.Paragraph>As a rule, your data will be accessed only by authorised persons having a legitimate business need to process these data.</Typography.Paragraph>
            <Typography.Paragraph>When required for the NETERIUM Purposes, we may share your data with carefully selected suppliers (for example, Customer Relationship or HR Management service providers). Before sharing your data, we require such suppliers to only process your Personal Data upon our instructions and to provide sufficient guarantees with regard to the technical and organisational security measures protecting the data Processing activities. Such suppliers may be located in or outside the European Economic Area (EEA) and we ensure the lawfulness of and appropriate safeguards for such transfers.</Typography.Paragraph>
            <Typography.Paragraph>We may also share your Personal Data with any competent law enforcement body, regulatory, government agency or other third party, where disclosure is necessary (i) as a matter of applicable law or regulation, or (ii) to exercise, establish or defend our legal rights.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="otherWebsites">Privacy policies of other websites</Typography.Title>
            <Typography.Paragraph>The NETERIUM website or services may contain links to other websites. Our Data Privacy Statement applies only to our website and services, so if you click on a link to another website, you should read their privacy policy.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="rights">Your data protection rights</Typography.Title>
            <Typography.Paragraph>Your Personal Data will not be kept for longer than necessary by NETERIUM, after which your Personal Data will be deleted.</Typography.Paragraph>
            <Typography.Paragraph>You have the right to be informed and this Data Privacy Statement clarifies the Personal Data we process, the rationale for their Processing and how we process these data. You also have the right to access, rectify, receive a copy or erase your Personal Data and restrict or object to Processing of your Personal Data. Note that these rights do not apply in all circumstances.</Typography.Paragraph>
            <Typography.Paragraph>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at <a href="mailto:privacy@neterium.io">privacy@neterium.io</a>. NETERIUM keeps tracks of requests received from individuals and related actions taken.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="contactingUs">Contacting us</Typography.Title>
            <Typography.Paragraph>If you have any questions about NETERIUM’s Data Privacy Statement, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Email us at <a href="mailto:privacy@neterium.io">privacy@neterium.io</a>.</Typography.Paragraph>
            <Divider />
            <Typography.Title level={4} id="belgianAuthority">Belgian data protection authority</Typography.Title>
            <Typography.Paragraph>Should you wish to report a complaint or if you feel that NETERIUM has not addressed your concern in a satisfactory manner, you may contact the Belgian Data Protection Authority (<a href="https://www.dataprotectionauthority.be">www.dataprotectionauthority.be</a>).</Typography.Paragraph>
            <Divider />
            <Typography.Paragraph><i>Last updated on 31 October 2022</i></Typography.Paragraph>
            <Divider />
            <Button type="primary">
                <a target="_blank" rel="noreferrer" href="https://neterium-portal-config.s3.eu-central-1.amazonaws.com/privacy/Neterium_Data_Privacy_Statement.pdf">View as PDF</a>
            </Button>
        </article>
    )
}
