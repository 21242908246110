import './../../assets/scss/ShowError.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default function ShowError(content: any) {
    return (
        <div className="errorContainer">
            <FontAwesomeIcon icon={faWarning} />{content.error}
        </div>
    )
}
