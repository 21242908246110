import '../../../assets/scss/commonModules/TechInfos.scss';

import { Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';

export default function TechInfos(props: any) {
    const { techProps } = props;

    if (techProps.length === 0) {
        return null
    }

    const items: DescriptionsProps['items'] = techProps.map((tp: any, index: number) => {
        return {
            key: index.toString(),
            label: tp.name,
            children: <p>{tp.value}</p>
        }
    })

    return (
        <Descriptions title="Technical Informations" column={4} items={items} />
    )
}
