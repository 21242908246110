import { Alert, Col, Flex, Row } from 'antd';
import { HomeFilled, HomeOutlined } from '@ant-design/icons';

import { Content } from 'antd/es/layout/layout';
import FullClusterStatusWidget from './../widgets/FullClusterStatusWidget';
import { ListsRecordsStatsWidget } from './../widgets/ListsRecordsStatsWidget';
import MotionBox from './../Layout/Animated/MotionBox';
import { PageHeader } from './../Layout/header/PageHeader';
import { ProductStatsContainer } from './../widgets/ProductStatsContainer';
import { ShowIfAccess } from '../../utils/secure';
import useUserInfos from '../../hooks/useUserInfos';

export default function Dashboard() {

    const { userInfos, loading: loadingUser, error: errorUser } = useUserInfos();
    return (
        <section className="homepage">
            <ShowIfAccess authenticated={true}>
                <MotionBox>
                    <Content className='features markdown'>
                        <PageHeader icon={<HomeFilled />}
                            title='Dashboard'
                            withEnvSelector
                            subtitle='Portal'
                            breadcrumbs={[{ title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', }]} />
                        <Content>
                            <Flex vertical gap="middle">
                                {userInfos && !loadingUser && errorUser === null && userInfos?.formattedContent?.status?.display &&
                                    <Row gutter={[24, 24]} >
                                        <Col xs={24} >
                                            <Alert
                                                message={userInfos?.formattedContent?.status?.message}
                                                type={userInfos?.formattedContent?.status?.type}
                                                showIcon />
                                        </Col>
                                    </Row>
                                }
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} xl={16}>
                                        <Row gutter={[24, 24]}>
                                            {/* Access to the following widgets is restricted by Stats role:*/}
                                            <ShowIfAccess role={['js_stats']} authenticated={true} namespaceRequired={true}>
                                                <Col xs={24}>
                                                    <ProductStatsContainer />
                                                </Col>
                                            </ShowIfAccess>
                                            {/* Access to the following widgets is restricted by List role:*/}
                                            <ShowIfAccess role={['portal_list_status', 'js_stats']} authenticated={true} namespaceRequired={true}>
                                                <Col xs={24}>
                                                    <ListsRecordsStatsWidget />
                                                </Col>
                                            </ShowIfAccess>
                                            {/* Access to the following widgets is restricted by Cluster/Service role:*/}
                                            <ShowIfAccess role={['nsadmin_packages']} authenticated={true} namespaceRequired={true}>
                                                <Col xs={24} >
                                                    {/* <PackagesWidget /> */}
                                                </Col>
                                            </ShowIfAccess>
                                        </Row>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Row gutter={[24, 24]}>
                                            {/* Access to the following widgets is restricted by Cluster/Service role:*/}
                                            <ShowIfAccess role={['portal_cluster_status']} authenticated={true} namespaceRequired={true}>
                                                <Col xs={24}>
                                                    <FullClusterStatusWidget />
                                                </Col>
                                            </ShowIfAccess>
                                        </Row>
                                    </Col>
                                </Row>
                            </Flex>
                        </Content>
                    </Content>
                </MotionBox>
            </ShowIfAccess>
        </section>
    )
}
