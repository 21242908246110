import '../../assets/scss/Loading.scss';

import { Spin } from 'antd';

export default function Loading() {
    return (
        <div className="loading">
            <Spin tip="Loading">
                <div className="content" />
            </Spin>
        </div>
    )
}
