import { Card, Col, Divider, Row, Statistic, Tooltip } from 'antd';

import { InfoCircleTwoTone } from '@ant-design/icons';
import MotionBox from '../../Layout/Animated/MotionBox';
import { SecureWithLogin } from '../../../utils/secure';

export default function TryItResultsStats(props: { responseCode: any }) {

    const { responseCode } = props;


    return (
        <SecureWithLogin role={['js_screen']} module='TRY_IT' callbackPath="/tryIt" namespaceRequired={true}>
            <MotionBox>
                {responseCode && responseCode?.outcome &&
                    <Row gutter={[24, 24]}>
                        <Col md={24} sm={24}>
                            <Divider orientation='left'>Screening summary</Divider>
                            <Card>
                            <Row  justify="space-between" gutter={[24,24]}>
                                <Col xs={24} md={12} lg={8} xxl={4}>
                                    <Statistic title={<center>Status</center>} value={responseCode?.outcome.toUpperCase()} valueStyle={responseCode.outcome === 'success' ? { textAlign: "center", color: '#3f8600' } : { textAlign: "center", color: '#cf1322' }} />
                                </Col>
                                <Col xs={24} md={12} lg={8} xxl={4}>
                                    <Statistic title={<center>Matches found</center>} value={responseCode?.stats?.hits} valueStyle={{ textAlign: "center" }} />
                                </Col>
                                <Col xs={24} md={12} lg={8} xxl={4}>
                                    <Tooltip title={
                                        <ul>
                                            {responseCode?.context?.jetscan?.lists.map((l: any) => {
                                                return <li key={l.key}>{l.name}</li>
                                            })}
                                        </ul>
                                    }>
                                    <Statistic title={<center>Collection <InfoCircleTwoTone /></center>} value={responseCode.context.collection.toUpperCase()} valueStyle={{ textAlign: "center" }} />
                                    </Tooltip>
                                </Col>
                                {/* <Col xs={24} md={12} lg={8} xxl={4}>
                                <Tooltip title={
                                        <ul>
                                            {responseCode?.context?.jetscan?.lists.map((l: any) => {
                                                return <li key={l.key}>{l.name}</li>
                                            })}
                                        </ul>
                                    }>
                                        <Statistic title={<center>Lists in collection <InfoCircleTwoTone /></center>} value={responseCode?.context?.jetscan?.lists.length} valueStyle={{ textAlign: "center" }} />
                                    </Tooltip>
                                </Col> */}
                                <Col xs={24} md={12} lg={8} xxl={4}>
                                    <Statistic title={<center>Screening duration</center>} value={responseCode?.stats?.elapsed} valueStyle={{ textAlign: "center" }} />
                                </Col>
                                <Col xs={24} md={12} lg={8} xxl={4}>
                                    <Statistic title={<center>API Version</center>} value={responseCode?.context?.jetscan?.api} valueStyle={{ textAlign: "center" }} />
                                </Col>
                            </Row>
                            </Card>

                        </Col>
                    </Row>
                }
            </MotionBox>

        </SecureWithLogin>
    )
}