import { motion } from "framer-motion"

export default function MotionBox(props: any) {
    return (
        <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 40 }}
        >
            {props.children}
        </motion.div>
    )
}
