import '../../assets/scss/Loading.scss';

import { Skeleton } from 'antd';

export default function LoadingSkeleton() {
    return (
        <div className="loading">
            <Skeleton active />
        </div>
    )
}
