import './../../../../assets/scss/CreateForms.scss';
import './../../../../assets/scss/FormServiceAccounts.scss';

import { ApiOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Radio, Space, Tooltip, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import { EnvContext } from '../../../../contexts/EnvironmentContext';
import LoadingSkeleton from '../../../Layout/LoadingSkeleton';
import { SecureWithLogin } from "../../../../utils/secure";
import { callBackEnd } from '../../../../utils/backEndUtils';
import useNamespaceById from '../../../../hooks/useNamespaceById';

export default function FormServiceAccount() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    useEffect(() => {
        outletContext?.setHeaderTitle("My Service Accounts");
    }, [outletContext]);

    const { getNamespacePackages } = useNamespaceById();

    const [form] = Form.useForm();
    const envContext: string = useContext(EnvContext);
    const navigate = useNavigate();

    const { namespaceId, serviceAccountId } = useParams();
    const formMode: string = serviceAccountId ? 'edit' : 'create';

    const [editedServiceAccount, setEditedServiceAccount] = useState<ServiceAccount | null>(null);

    const [loadingPackages, setLoadingPackages] = useState<boolean>(true);
    const [errorPackages, setErrorPackages] = useState<any>(null);
    const [packages, setPackages] = useState<Package[] | null>(null);


    const formData: ServiceAccountsFormDataType = {
        packageId: "",
        namespaceId: '',
        projectId: null,
        name: '',
        description: '',
        environment: '',
    }
    const formDataUpdate: ServiceAccountsUpdateFormDataType = {
        namespaceId: '',
        name: '',
        description: '',
        environment: ''
    }

    //Get the service account data for edit mode
    useEffect(() => {

        async function getServiceAccount() {
            try {
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/service-accounts/${serviceAccountId}`);
                form.setFieldsValue({
                    name: response.name,
                    description: response.description,
                    packageId: response.packageId,
                })
                setEditedServiceAccount(response);

            } catch (error: any) {
                console.log(error);
            }
        }

        if (formMode === 'edit' && serviceAccountId && namespaceId) {

            getServiceAccount().catch((e: any) => console.log(e));
        }
    }, [namespaceId, serviceAccountId, formMode, form]);

    useEffect(() => {
        async function getPackage() {
            try {
                setLoadingPackages(true);
                const response: any = await getNamespacePackages({ withVolumes: true });
                setPackages(response.filter((p: Package) => p?.type === 3));

            } catch (error: any) {
                setErrorPackages(error);
            } finally {
                setLoadingPackages(false);
            }
        }

        if (namespaceId !== '') {
            getPackage().catch((e: any) => console.log(e));

        }
    }, [namespaceId, envContext, getNamespacePackages]);


    const handleForm = (values: any) => {
        async function saveServiceAccount(data: ServiceAccountsFormDataType | ServiceAccountsUpdateFormDataType) {

            try {
                //Edit
                if (formMode === 'edit' && serviceAccountId !== undefined) {
                    await callBackEnd('portal', 'PUT', `namespaces/${namespaceId}/service-accounts/${serviceAccountId}`, JSON.stringify(data));
                }
                else {
                    //Create
                    await callBackEnd('portal', 'POST', `namespaces/${namespaceId}/service-accounts`, JSON.stringify(data));
                }
                navigate(`/nsadmin/service-accounts`);
            } catch (error: any) {
                console.log(error);
            }
        }

        if (namespaceId && namespaceId !== '') {
            if (formMode !== 'edit') {
                formData.name = values.name ?? '';
                formData.description = values.description ?? '';
                formData.packageId = values.packageId;
                formData.namespaceId = namespaceId;
                formData.environment = envContext;
            } else {
                formDataUpdate.name = values.name ?? '';
                formDataUpdate.description = values.description ?? '';
                formDataUpdate.namespaceId = namespaceId;
                formDataUpdate.environment = editedServiceAccount?.environment;
            }
            saveServiceAccount((formMode !== 'edit') ? formData : formDataUpdate).catch((error) => { console.log(error) });
        }
    }
    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_service_accounts']} namespaceRequired={true}>
            <Content>
                {!loadingPackages && errorPackages === null ?
                    <Card
                        title={(formMode !== 'edit') ? <><ApiOutlined /> New Service Account</> : <><ApiOutlined /> Edit Service Account: {editedServiceAccount?.name}</>}
                        className="serviceAccountFormAnt"
                    >
                        <Form
                            labelWrap
                            form={form}
                            onFinish={handleForm}
                            onValuesChange={() => form.resetFields(['environment'])} // Trigger re-render on values change
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 21 }}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your Service Account name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input your Service Account description' }]}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                            label="Environment"
                            name="environment"
                            rules={[{ required: true, message: 'Please input your Service Account environment' }]}
                            initialValue={envContext}
                        >
                            <Radio.Group onChange={(e) => handleChangeEnvironment(e.target.value)}>
                                {allEnv.map((env: any) => {
                                    return (
                                        <Radio key={env.name} value={env.name}>
                                            <Typography.Text>{env.label}</Typography.Text>
                                        </Radio>
                                    )
                                })}
                            </Radio.Group>
                        </Form.Item> */}
                            <Form.Item
                                label="Package"
                                name="packageId"
                                rules={[{ required: true, message: 'Please select a package' }]}
                            >
                                <Radio.Group disabled={formMode === "edit"}>
                                    <Space direction="vertical" size="large">
                                        {packages !== null && packages.filter((p: Package) => p.environment === envContext).map((p: Package) => {
                                            return (
                                                <Radio key={p.uid} value={p.uid}>
                                                    <Tooltip title={<>
                                                        Expiration: {p.expiration ? p.expiration : 'No expiration date'}
                                                    </>}>
                                                        <Typography.Text>{p.name} <InfoCircleOutlined /></Typography.Text>
                                                    </Tooltip>
                                                    <br />
                                                    {p.volumes && p?.volumes?.length > 0 &&
                                                        <>
                                                            {p.volumes.map((v: Volume) => {
                                                                return (
                                                                    <Typography.Text key={v.uid} type="secondary">
                                                                        {v.product} ({v.collection}: {v.amount} / {v.cycle})<br />
                                                                    </Typography.Text>
                                                                )
                                                            }
                                                            )}
                                                        </>
                                                    }
                                                </Radio>
                                            )
                                        })}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                <Space size="middle">
                                    <Button type="text" onClick={() => navigate(`/nsadmin/service-accounts`)}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                    :
                    <LoadingSkeleton />
                }
            </Content>

            {/* <div className="actions">
                        <button className="customSmallButton success">
                            <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
                            {' '}
                            {(formMode === 'edit') ? 'Save Changes' : 'Create Service Account'}
                        </button>

                        <Link to={`/nsadmin/service-accounts`}>
                            <button className="customSmallButton base">
                                <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                                {' '}
                                Cancel
                            </button>
                        </Link>
                    </div> */}


        </SecureWithLogin>
    )
}
