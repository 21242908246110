import { Button, Card, Drawer, Space, Table, TableColumnsType, Tag, Tooltip, notification } from 'antd';
import { EditOutlined, EyeTwoTone, InfoCircleTwoTone, PieChartOutlined } from '@ant-design/icons';
import { Link, useOutletContext } from 'react-router-dom';
import { SecureWithLogin, useOidcAccessToken } from '../../../../utils/secure';
import { useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../../../Layout/LoadingSkeleton';
import Moment from 'react-moment';
import MotionBox from '../../../Layout/Animated/MotionBox';
import NSAPackageVolumes from '../NSAPackageVolumes';
import ShowError from '../../../Layout/ShowError';
import StatusPills from '../../../crm/common/StatusPills';
import { getAsyncPackageTypes } from '../../../../utils/getPackageTypes';
import useAllEnvironments from '../../../../hooks/useAllEnvironments';
import useNamespaceById from '../../../../hooks/useNamespaceById';

export default function NSAPackages() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    const { getNamespacePackages, getNamespaceServiceAccounts } = useNamespaceById();
    const { allEnv } = useAllEnvironments();

    useEffect(() => {
        outletContext.setHeaderTitle("My Packages");
    }, [outletContext]);

    const { accessTokenPayload } = useOidcAccessToken();
    const [namespaceId, setNamespaceId] = useState<string>("");
    const [packages, setPackages] = useState<any>(null);
    const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
    const [errorPackages, setErrorPackages] = useState<any>(null);
    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<any>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
    const [staticPackageTypes, setStaticPackageTypes] = useState<StaticPackageType[] | null>(null);


    useEffect(() => {
        if (accessTokenPayload?.namespaceId) {
            setNamespaceId(accessTokenPayload?.namespaceId);
        }
    }, [accessTokenPayload]);

    useEffect(() => {
        async function getPackages() {
            try {
                setLoadingPackages(true);
                const response: any = await getNamespacePackages({ withVolumes: true }).catch((e: any) => console.log(e));
                setPackages(response);
            } catch (error: any) {
                notification.error({ message: 'An error occured while loading packages' });
                setErrorPackages(error);
            } finally {
                setLoadingPackages(false);
            }
        }
        async function getServiceAccounts() {
            setLoadingServiceAccounts(true);
            const response: any = await getNamespaceServiceAccounts().catch((e: any) => {
                notification.error({ message: 'An error occured while loading service accounts' });
                setErrorServiceAccounts(e)
            }).finally(() => setLoadingServiceAccounts(false));

            setServiceAccounts(response);
        }
        async function getStaticPackageTypes() {
            try {
                const packageTypes: any = await getAsyncPackageTypes();
                if (packageTypes?.length > 0) {
                    setStaticPackageTypes(packageTypes);
                }
            } catch (error: any) {
                notification.error({ message: 'An error occured while loading package types' });
            }
        }
        getStaticPackageTypes().catch((error) => { console.log(error) });
        if (namespaceId !== "") {
            getPackages().catch((e: any) => console.log(e));
            getServiceAccounts().catch((e: any) => console.log(e));
        }
    }, [namespaceId, getNamespacePackages, getNamespaceServiceAccounts])

    const getNameFromStaticPackageTypes = (type: string | number) => {
        if (staticPackageTypes) {
            const t: StaticPackageType | undefined = staticPackageTypes.find((t: StaticPackageType) => t.id === type);
            if (t) {
                return t?.label.toUpperCase();
            }
            return type;
        }
        return type;
    }

    const countPackageIdInServiceAccount = (packageId: string) => {
        let count: number = 0;
        if (packageId) {
            serviceAccounts?.forEach((sa: ServiceAccount) => {
                if (sa.packageId === packageId) {
                    count++;
                }
            })

        }
        return count;
    }



    const renderOptions = (options: any) => {
        if (options?.length > 0) {
            return (
                <Tooltip
                    title={
                        <ul>
                            {options.map((o: any, index: number) => {
                                return (
                                    <li key={index}>
                                        {o.name}
                                        {/* <td>{(o.enabled) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faBan} />}</td> */}

                                        {o.limits !== null &&
                                            <>
                                                {' > '}
                                                {
                                                    Object.keys(o.limits).map((k: any, index: number) => {
                                                        return (<small key={k.name}>
                                                            {k}
                                                            :{' '}
                                                            {o?.limits[k]}
                                                        </small>)
                                                    })
                                                }
                                            </>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    } placement="top">
                    <EyeTwoTone twoToneColor="#52c41a" />
                </Tooltip>
            )
        }
        return "";
    }

    const showDrawerVolumes = (packageId: string) => {
        setSelectedPackage(packages.find((p: Package) => p.uid === packageId));
        setDrawerOpen(true);
    }

    const columns: TableColumnsType<Package> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <StatusPills type={status} />,
            filters: [
                { text: 'Enabled', value: "enabled" },
                { text: 'Disabled', value: "disabled" }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Package) => {
                return (
                    <>
                        {(record?.description) ?
                            <Tooltip title={record.description}>
                                {name} <InfoCircleTwoTone />
                            </Tooltip>
                            :
                            <>{name}</>
                        }
                    </>
                )
            }
        },
        {
            title: 'Environment',
            dataIndex: 'environment',
            key: 'environment',
            filters: allEnv.map((e: any) => {
                return { text: e.label, value: e.name }
            }),
            onFilter: (value: any, p: any) => p.environment === value,
            render: (env: string) => {
                const color: string | undefined = allEnv.find((e: any) => e.name === env)?.color;
                if (color) {
                    return <Tag color={color}>{env}</Tag>
                }
                else {
                    return <Tag>{env}</Tag>
                }
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: any) => {
                return <Tag color={"blue"}>{getNameFromStaticPackageTypes(type)}</Tag>
            },
            filters: staticPackageTypes?.map((t: StaticPackageType) => {
                return { text: t.label, value: t.id }
            }),
            onFilter: (value: any, p: any) => p.type === value,
        },
        {
            title: 'Expiration',
            dataIndex: 'expiration',
            key: 'expiration',
            render: (v: Date | null) => {
                if (v === null) {
                    return <>No expiration</>
                }
                return <Moment format="YYYY/MM/DD">{v}</Moment>
            }
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (o: any) => renderOptions(o)
        },
        {
            title: 'User Default',
            dataIndex: 'userDefault',
            key: 'userDefault',
            render: (status: string) => <StatusPills type={status ? "enabled" : "disabled"} />,
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
        },
        {
            title: 'Service Accounts',
            dataIndex: 'uid',
            key: 'uid',
            render: (uid: string) => {
                return countPackageIdInServiceAccount(uid)
            }
        },
        {
            title: 'Volumes',
            dataIndex: 'volumes',
            key: 'y',
            render: (value: any, p: Package) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Quick look at volumes" placement="top">
                            <Button type="link" icon={<PieChartOutlined />} onClick={(e: any) => showDrawerVolumes(p.uid)}>View Volumes</Button>
                        </Tooltip>
                    </Space>
                )
            }

        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'x',
            render: (value: any, p: Package) => {
                return (
                    <Space size="middle">
                        <Tooltip title="View package details, assign users to this Package" placement="top">
                            <Link to={`/nsadmin/${namespaceId}/packages/${p.uid}`}>
                                <Button type="link"><EditOutlined /> Edit</Button>
                            </Link>
                        </Tooltip>
                    </Space>
                )
            }

        }
    ];


    return (

        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_packages']} namespaceRequired={true}>
            <MotionBox>
                {(!loadingPackages && packages !== null && errorPackages === null && !loadingServiceAccounts && errorServiceAccounts === null && serviceAccounts && namespaceId && staticPackageTypes) &&
                    <Content>
                        <Card
                            title="My Packages"
                        >
                            <Table
                                dataSource={packages}
                                columns={columns}
                                rowKey="uid"
                                scroll={{ x: 'max-content' }}
                                pagination={{
                                    showSizeChanger: packages?.length > 10,
                                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                                    defaultPageSize: 20
                                }}
                            />
                        </Card>
                        <Drawer
                            title={<><PieChartOutlined /> Volumes of {selectedPackage?.name}</>}
                            placement="bottom"
                            closable={true}
                            onClose={() => { setDrawerOpen(false) }}
                            open={drawerOpen}
                            size="large"
                            destroyOnClose={true}
                        >
                            {selectedPackage && <NSAPackageVolumes pkg={selectedPackage} />}
                        </Drawer>
                    </Content>
                }
            </MotionBox>
            {loadingPackages && <LoadingSkeleton />}
            {errorPackages && <ShowError error={errorPackages.toString()} />}
        </SecureWithLogin>
    )
}