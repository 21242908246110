import { Collapse, Tree } from 'antd';
interface ShowOptionsProps {
    readonly options: any;
    readonly defaultOpen: boolean;
}

export default function ShowOptions(props: ShowOptionsProps) {

    const generateTreeData = (options:any): any[] => {
        return Object.keys(options).map((key) => {
            const value = options[key];
            if (Object.prototype.toString.call(value) === '[object Object]') {
                return {
                    title: key,
                    key: key+crypto.randomUUID(),
                    children: generateTreeData(value),
                };
            } else if (Object.prototype.toString.call(value) === '[object Array]') {
                return {
                    title: key,
                    key: key + crypto.randomUUID(),
                    children: value.map((item:any, index:number) => ({ title: item, key: `${crypto.randomUUID()}-${key}-${index}` })),
                };
            } else {
                return {
                    title: `${key}: ${value}`,
                    key: key + crypto.randomUUID(),
                };
            }
        });
    };


    return (
        <>
            {(props.options !== null) &&
                <>
                <Collapse defaultActiveKey={props.defaultOpen ? ['1']: []}
                    items={[
                        {
                            key: '1',
                            label: 'Options in formatted display',
                            children: Object.keys(props.options).length === 0 ? (
                                <p>Default options will apply</p>
                            ) : (
                                    <Tree defaultExpandAll treeData={generateTreeData(props.options)} />
                            ),
                        },
                    ]}
                />
                    
                </>


            }
        </>
    )
}
