import { Bar, BarChart, Brush, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import { Typography } from "antd";
import moment from "moment";

export default function CustomBarChartStacked(props: { stats: Promise<any>, product: string, title: string }) {
    const { stats, product, title } = props;
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await stats;
                const chartData: any = result.map((s: any) => {
                    s.formattedDate = moment(s.day).format('YYYY-MM-DD');
                    s.hit = s.hitRecords;
                    s.nohit = s.screenedRecords - s.hitRecords;
                    s.recovered = s.recoveredRecords;
                    s.errored = s.errorRecords;
                    return s;
                });
                setData(chartData);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [stats]);




    return (
        <>
            {
                ((data.length > 0 && !loading)) ?
                    <>
                        <Typography.Title level={5}>
                            {title}
                        </Typography.Title>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 10
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={"formattedDate"} />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                {data.length > 1 &&
                                    <Brush dataKey="name" height={20} stroke="#82b9d6" />
                                }
                                {/* <Legend /> */}
                                <Bar dataKey="hit" stackId="a" fill={product.toLowerCase() === 'jetscan' ? "#38bdf8" : '#f59e0b'} />
                                <Bar dataKey="nohit" stackId="a" fill="#648DA3" />
                                <Bar dataKey="errored" stackId="a" fill="#ef4444" />
                                <Bar dataKey="recovered" stackId="a" fill="#22c55e" />
                            </BarChart>
                        </ResponsiveContainer>
                    </>
                    :
                    <LoadingSkeleton />
            }
        </>
    )
}
