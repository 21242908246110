import { Button, Card, Input, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { EyeTwoTone, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import { ShowIfAccess } from '../../../utils/secure';
import StatusPills from '../../crm/common/StatusPills';
import { useState } from 'react';

export default function NSAUsersList(props: Readonly<INSAUsersListProps>) {
    const { users, title, namespaceId } = props;
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();

    const columns: TableColumnsType<UserProfile> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (value: boolean) => <StatusPills type={value} />,
            filters: [
                { text: 'True', value: true },
                { text: 'False', value: false },
            ],
            onFilter: (value: any, u: UserProfile) => u.enabled === value,
        },
        // {
        //     title: 'Last Login',
        //     dataIndex: 'lastLogin',
        //     key: 'lastLogin',
        //     render: (value: Date) => <Moment date={value} />,
        // },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'x',
            render: (value: any, u: UserProfile) => <Tooltip title="View user details" placement="top"><Button type="link" onClick={() => navigate(`/nsadmin/${namespaceId}/users/${u.uid}`)}><EyeTwoTone /></Button></Tooltip>,
        }
    ];

    return (
        <Content>
            <Card
                title={<><UsergroupAddOutlined /> {title}</>}
                className="userList"
                extra={
                    <Space size="small">
                        <Input name="text" type="text" placeholder="Search" className="search" onChange={(e: any) => setSearch(e.target.value)} />
                        <ShowIfAccess authenticated={true} role={['nsadmin_users']}>
                            <Link to={`/nsadmin/${namespaceId}/users/invite`}>
                                <Button type="primary" icon={<UserAddOutlined />}>
                                    Invite new users
                                </Button>
                            </Link>
                        </ShowIfAccess>
                    </Space>
                }
            >
                <Table
                    dataSource={users.filter((u: UserProfile) => {
                        if (search === '') return u;
                        else if (u.firstName && u?.firstName.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.lastName && u?.lastName.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.email && u?.email.toLowerCase().includes(search.toLowerCase())) return u;
                        else if (u.company && u?.company.toLowerCase().includes(search.toLowerCase())) return u;
                        // else if (u.status && u.status.toLowerCase().includes(search.toLowerCase())) return u;
                        else return null;
                    })}
                    columns={columns}
                    rowKey="uid"
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        showSizeChanger: users.length > 10,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        defaultPageSize: 10
                    }}
                />
            </Card>

        </Content>
    )
}
