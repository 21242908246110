import { ApiOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ContainerTwoTone } from '@ant-design/icons';
import { Card, Flex, Table, TableColumnType, Tooltip } from 'antd'

import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox'
import { SecureWithLogin } from '../../../utils/secure'
import useAllEnvironments from '../../../hooks/useAllEnvironments';

export default function ServiceAccountList(props: Readonly<IServiceAccountListProps>) {
    const { serviceAccounts, packages } = props;
    const { allEnv } = useAllEnvironments();

    const columns: TableColumnType<ServiceAccount>[] = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            filters: [
                { text: 'Active', value: "ACTIVE" },
                { text: 'Inactive', value: "INACTIVE" },
                { text: 'Archived', value: "ARCHIVED" },
            ],
            onFilter: (value: any, p: any) => p.status === value,
            render: (status: string) => {
                let content: any = null;
                if (status === 'ACTIVE') {
                    content = <CheckCircleTwoTone twoToneColor="#52c41a" />
                } else if (status === 'INACTIVE') {
                    content = <CloseCircleTwoTone twoToneColor="#eb2f96" />
                } else if (status === 'ARCHIVED') {
                    content = <ContainerTwoTone />
                }
                return <Tooltip title={status}>{content}</Tooltip>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Environment',
            dataIndex: 'environment',
            key: 'environment',
            sorter: (a, b) => a.environment.localeCompare(b.environment),
            filters: allEnv?.map((e: any) => {
                return { text: e.label, value: e.name }
            }),
            onFilter: (value: any, p: any) => p.environment === value,
        },
        {
            title: 'Related Packages',
            dataIndex: 'packageIds',
            key: 'packageIds',
            render: (v: string[]) => {
                let content:any = v?.map((p: string) => {
                    const pack = packages.find((pck: any) => pck.uid === p);
                    return <Tooltip title={pack?.name} key={pack?.uid}>{pack?.name}</Tooltip>
                })
                return <Flex vertical gap={'small'}>{content}</Flex>

            }
        },
        {
            title: 'Client ID',
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
        },
        {
            title: 'Authentication Type',
            dataIndex: 'authenticationType',
            key: 'authenticationType',
            render: (v: any) => v || 'No authentication set'
        },
        {
            title: 'Last Updated',
            dataIndex: 'updated',
            key: 'updated',
            sorter: (a, b) => a.updated - b.updated,
            render: (v: any) => <Moment format="YYYY/MM/DD h:mm:ss a">{v}</Moment>
        }

    ]

    return (
        <SecureWithLogin callbackPath="/packages" role={['crm_packages']} namespaceRequired={true}>
            <MotionBox>
                <Card
                    type="inner"
                    title={<><ApiOutlined />{' '}Service Accounts</>}
                >
                    <Table
                        dataSource={serviceAccounts.sort((a, b) => a.status.localeCompare(b.status))}
                        columns={columns}
                        rowKey="uid"
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            showSizeChanger: serviceAccounts.length > 10,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            defaultPageSize: 10
                        }}
                    />

                </Card>

            </MotionBox>
        </SecureWithLogin>
    )
}
