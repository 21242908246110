
const getClusterStatus = (status: string): string => {
    if (status === 'GREEN' || status === 'UP') {
        return 'success';
    } else if ( status === 'YELLOW' ) {
        return 'warning';
    } else if ( status === 'RED' || status === 'OUT_OF_SERVICE' || status === 'DOWN' ) {
        return 'error';
    }
    return ''
  }
  export { getClusterStatus };
