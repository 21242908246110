import { EnvContext, EnvUpdateContext } from '../../contexts/EnvironmentContext';
import { Radio, Tooltip } from 'antd';

import useAllEnvironments from '../../hooks/useAllEnvironments';
import { useContext } from 'react'

export default function EnvSelector({ disableProd = false }: { disableProd?: boolean }, { disabled = false }: { disabled?: boolean }) {
    const env = useContext(EnvContext);
    const updateEnvironment = useContext(EnvUpdateContext);
    const { allEnv }: any = useAllEnvironments();

    const handleChangeEnvironment = (value: any) => {
        if (updateEnvironment) {
            updateEnvironment(value);
        }
    }

    return (
        <div>
            <Radio.Group defaultValue={env} onChange={(e: any) => handleChangeEnvironment(e.target.value)} buttonStyle="solid" disabled={disabled}>
                {allEnv.map((envItem: any) => {
                    const isSelected = env === envItem.name;
                    if (envItem.name === 'PROD' && disableProd) {

                        //NO PROD => Fallback on sandbox
                        if (isSelected) {
                            handleChangeEnvironment('SANDBOX');
                        }
                        return null;

                    }
                    return <Tooltip title={"Switch to the "+envItem.label+" environment"} key={envItem.name}>
                        <Radio.Button
                            value={envItem.name}
                            style={isSelected ? { backgroundColor: envItem.color } : {}}
                        >
                            {envItem.label}
                        </Radio.Button>
                    </Tooltip>
                })}
            </Radio.Group>
        </div>
    )
}
