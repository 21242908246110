import "./../../assets/scss/Footer.scss";

import { Link } from "react-router-dom";
import Moment from "react-moment";

export default function Footer() {
    return (
        <footer className="appFooter">
            <span>
                Neterium SRL{' '}&copy;
                {' '}<Moment format="YYYY" /></span>{' '}-{' '}
                <span><Link to='/privacy-statement'>Privacy Statement</Link></span>
                {' '}-{' '}<span><a href="https://neterium-portal-config.s3.eu-central-1.amazonaws.com/terms/Neterium_Portal_Terms_of_Use.pdf" target='_blank' rel="noreferrer">Terms of Use</a></span>
        </footer>
    )
}
