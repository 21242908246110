import '../../../../assets/scss/CreateOptions.scss';

import { useEffect, useState } from "react";

import Loading from "../../../Layout/Loading";
import ShowError from '../../../Layout/ShowError';
import useOptions from "../../../../hooks/useOptions";

export default function FormOptions(props: IOptionsProps) {
    const { handleOptions, defaultValues } = props;
    const { options, loading: optionsLoading, error: optionsError }: { options: OptionModel[], loading: boolean, error: any } = useOptions();
    const [optionsValues, setOptionsValues] = useState<any[]>((defaultValues !== null) ? defaultValues : []);
    const formMode: string = defaultValues !== null ? 'edit' : 'create';


    useEffect(() => {
        if (options.length > 0) {
            const formattedOptions: any = options.map((o: any) => {
                const inDefaultValues: any = (defaultValues !== null) ? defaultValues.filter((ov: any) => ov.name === o.name) : [];
                o.enabled = inDefaultValues.length > 0 ? true : false;
                if (o.hasOwnProperty('limits')) {
                    o.limits?.forEach((l: any, i: number) => {
                        if (inDefaultValues.length > 0 && inDefaultValues[0].hasOwnProperty('limits')) {
                            o.limits[i].value = inDefaultValues[0].limits[l.name];
                        } else {
                            l.value = l.def;
                        }
                    })
                }
                return o;
            })
            setOptionsValues(formattedOptions);
        }
    }, [options, defaultValues, formMode]);

    useEffect(() => {
        const formattedOptions: any[] = optionsValues.filter((o: any) => o.enabled).map((o: any) => {
            let formatted: any = {};
            formatted.enabled = true;
            formatted.name = o.name;
            if (o.hasOwnProperty('limits')) {
                formatted.limits = {};
                o?.limits?.forEach((l: any) => {
                    formatted.limits[l.name] = l.value;
                })
            }
            return formatted;
        })
        handleOptions(formattedOptions);
    }, [optionsValues, handleOptions])
    

    const handleChangeOptions = (id: string, value: boolean) => {
        let newOptionsValues: any = [...optionsValues];
        const element: any = newOptionsValues.filter((o: any) => {
            if (o.name === id) {
                return o;
            }
            return null;
        })
        if (element.length > 0) {
            element[0].enabled = value;
        }

        setOptionsValues(newOptionsValues);
    }

    const handleChangeLimits = (parent: string, limitName: string, value: number) => {
        let newOptionsValues: any = [...optionsValues];
        const element: any = newOptionsValues.filter((o: any) => {
            if (o.name === parent) {
                return o;
            }
            return null;
        })
        if (element.length > 0 && element[0].hasOwnProperty('limits')) {
            const limitElement: any = element[0].limits.find((l: any) => l.name === limitName);
            limitElement.value = value;
        }
        setOptionsValues(newOptionsValues);
    }

    return (
        <>
            {(options.length > 0 && optionsError === null && !optionsLoading && optionsValues.length) ?
                <div className="options">
                    {options.map((o: OptionModel) => {
                    
                        return (
                            <div key={o.name}>
                                {/* <input type="checkbox" name={o.name} onChange={e => handleChangeOptions(o.name, e.target.checked)} checked={(formMode === 'edit') ? o.enabled : false} /> {o.label} {(o.enabled)?'yes':'no'} */}
                                <input type="checkbox" name={o.name} onChange={e => handleChangeOptions(o.name, e.target.checked)} checked={o.enabled} /> {o.label}
                                {(o.hasOwnProperty('limits')) &&
                                    <div className='optionLimits'>
                                        {o.limits && o.limits.map((l: any) => {
                    
                                            return (
                                                <p key={l.name}>
                                                    <label>{l.label}</label>
                                                    <input type="number" min={l.min} max={l.max} value={l.value} onChange={e => handleChangeLimits(o.name, l.name, Number(e.target.value))} />
                                                </p>
                                            )
                                        })}
                                        {/* <br /> */}
                                        {/* <input type="number" min={o.limits?.min} max={o.limits?.max} defaultValue={o.limits?.def} /> */}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                :
                <>
                    {optionsLoading && <Loading />}
                    {optionsError && <ShowError error={optionsError.toString()} />}
                </>
            }
        </>
    )
}
