import { AimOutlined, PlusCircleOutlined, SettingFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';

interface StatsProps {
    stats: { screener: string, screenedRecords: number, hits: number, hitRecords: number, hitPercent: string }
    collection: string
}

function Stats(props: StatsProps) {
    const statsToDisplay: Array<{ icon: any, label: string, value: number | string }> = [
        { icon: <SettingFilled />, label: 'Engine', value: props.stats.screener.toUpperCase() },
        { icon: <UnorderedListOutlined />, label: 'Collection', value: props?.collection.toUpperCase() },
        { icon: <UnorderedListOutlined />, label: 'Screened Records', value: props.stats.screenedRecords },
        { icon: <AimOutlined />, label: 'Alerted records', value: `${props.stats.hitRecords} (${Math.round(((props.stats.hitRecords / props.stats.screenedRecords) * 100) * 100) / 100}%)` },
        { icon: <PlusCircleOutlined />, label: 'Total matches', value: props.stats.hits },
    ];

    return (
        <Row gutter={[16, 16]}>
            {statsToDisplay?.map((stat, index) => {
                return (
                    <Col flex={2} key={index+"-"+crypto.randomUUID()}>

                        <Statistic
                            title={stat.label}
                            value={stat.value}
                            valueStyle={{ color: 'primary' }}
                            prefix={stat.icon}
                        />

                    </Col>
                )

            })}
        </Row>

    )
}
export default Stats;