import { Button, Card, Col, Result, Row } from 'antd'
import { SecureWithLogin, useOidcAccessToken } from '../../utils/secure'

import { Content } from 'antd/es/layout/layout'
import { EnvContext } from '../../contexts'
import { HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader'
import TryItForm from './TryItForm'
import { getAvailableCollectionByProduct } from '../../utils/getCollections'
import { useContext } from 'react'

const TryItHome = () => {


    const env: string = useContext(EnvContext);
    const { accessTokenPayload } = useOidcAccessToken();


    // const availableCollections = useMemo(() => getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload), [env, accessTokenPayload]);

    return (
        <SecureWithLogin role={['js_screen']} module='TRY_IT' callbackPath="/tryIt" namespaceRequired={true}>
            <MotionBox>
                <Content>
                    {/* Page Header */}
                    <PageHeader
                        title='Try the API'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Try the API</span> }]
                        }
                        withEnvSelector
                    />

                    {/* <Divider orientation="left">Your query to the API</Divider> */}
                    {getAvailableCollectionByProduct('JETSCAN', env, accessTokenPayload,1).includes('sanctions') ?
                        <>
                            {/* <Row gutter={[24,24]} style={{maxWidth:'1280px', margin:'0 auto'}}> */}
                            <Row gutter={[24, 24]} >

                                <Col xs={24}>
                                    <TryItForm />
                                </Col>

                            </Row>
                        </>
                        :
                        <Card>
                            <Result
                                status="error"
                                title="You need to have access to the SANCTIONS collection on this environment to use this feature."
                                subTitle="Try changing your environment using the Environment Selector on top of your screen of contact your administrator."
                                extra={
                                    <Link to="/">

                                        <Button type="primary" key="home">
                                            Back to homepage
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    }
                    {/* <Divider orientation="left">API documentation</Divider> */}
                    {/* <Divider/>
                    <p style={{ textAlign: "center" }}>Go to the API documentation{' '}<Link to="/doc/">API documentation</Link>{' '}to learn more about the API and how to use it.</p> */}
                </Content>
            </MotionBox>
        </SecureWithLogin >
    )
}
export default TryItHome
