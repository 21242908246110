const environments: { name: string, label: string }[] = [
    {
        name: 'DEV',
        label: 'Dev'
    },
    {
        name: 'SANDBOX',
        label: 'Sandbox'
    },

    {
        name: 'TEST',
        label: 'Test'
    },
    {
        name: 'ON_PREMISE',
        label: 'On Premise'
    },
    {
        name: 'PROD',
        label: 'Production'
    }
];

export const getEnvironments = () => {
    return environments
};
export const getFormattedEnvironment = (env: string) => {
    const fEnv: { name: string, label: string }[] = environments.filter((item) => item.name === env.toUpperCase());
    return fEnv.length ? fEnv[0] : null;
};