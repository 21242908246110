import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { callBackEnd } from "../utils/backEndUtils";
import { createContext } from "react";
import { useOidc as getOidc, } from '@axa-fr/react-oidc';

export const RolesContext = createContext<RolesProviderType | null>(null);

interface RolesProviderProps {
    children: ReactNode;
    roles?: RolesType;
    loading?: boolean;
    namespaceId?: string;
    modules?: boolean[];
    userInfos?: any;
}

const RolesContextProvider: React.FC<RolesProviderProps> = ({ children }) => {

    const [roles, setRoles] = useState<RolesType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [namespaceId, setNamespaceId] = useState<string | null>(null);

    const [modules, setModules] = useState<boolean[]>([]);
    const [userInfos, setUserInfos] = useState<any>(null);

    const { isAuthenticated } = getOidc('config');



    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const userInfos: any = await callBackEnd('portal', 'GET', `user_info`);
                setRoles(userInfos?.roles);
                setUserInfos(userInfos);

                if (userInfos?.namespace) {
                    setModules(userInfos?.namespace?.options?.moduleAccess);
                    setNamespaceId(userInfos?.namespace?.uid);
                }
            } catch (error) {
                console.error('Failed to fetch roles:', error);
                setRoles(null);
                throw error;
            } finally {
                setLoading(false);
            }
        }
        if (isAuthenticated) {
            fetchRoles().catch((e: any) => console.log(e));
        } else {
            setRoles(null);
            setLoading(false);
            if (location.pathname !== '/login' && location.pathname !== '/logout' && location.pathname !== '/callback' && location.pathname !== '/') {
                navigate('/login', { state: { from: location.pathname } });
                console.log("Path set to: ", location.pathname);
            }

        }
    }, [isAuthenticated, navigate, location]);

    return (
        <RolesContext.Provider value={{ roles, loading, namespaceId, modules, userInfos }}>
            {children}
        </RolesContext.Provider>
    )
}

export function useRoles() {
    return useContext(RolesContext);
}

export default RolesContextProvider;