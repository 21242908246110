

export const getAvailableCollectionByProduct = (engine: string, env: string, accessTokenPayload: any, type: number | null = null) => {

    let tokenPkg: any[] = accessTokenPayload?.pkg;

    if (tokenPkg) {
        if (type !== null) {
            tokenPkg = tokenPkg.filter((p: any) => p.typ === type);
        }
        tokenPkg = tokenPkg.filter((p: any) => p.env === env.toLowerCase());
    }

    let collections: string[] = [];

    tokenPkg.forEach((p) => {
        p?.vols.forEach((v: any) => {
            if (v?.eng.toLowerCase() === engine?.toLowerCase()) {
                collections.push(v?.coll.toLowerCase());
            }
        })
    })    

    return [...new Set(collections)];
}