import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import moment from "moment";

export default function useLists(env: string, dateStart: string, dateEnd: string) {
    //List status, Loading and Error state
    const [lists, setLists] = useState<ListStatus[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    //selcted environment
    const targetEnv: string = `${env}`;
    useEffect(() => {
        async function getLists() {
            try {
                setLoading(true);

                //Get all lists
                const response: any = await callBackEnd(targetEnv, 'GET', 'api/v1/core/repository/lists');
                //sort list by name
                response.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
                
                const allHistory: ListStatus[] = await Promise.all(response.data.map(async (list: any) => {
                    let listCopy: any = list;
                    //Get list history
                    const history: any = await callBackEnd(targetEnv, 'GET', `api/v1/loader/updates/stats/${list.key}/?fromDate=${dateStart}&toDate=${dateEnd}`).catch((e: any) => console.log(e));
                    const hasDelta: boolean = history.data.some((item: any) => item.mode === 'delta');

                    //Map history data to chart data
                    const chartData: any = history.data.map((item: any) => {
                        return {
                            executionTime: moment(item.executionTime).format('YYYY-MM-DD'),
                            total: item.databaseTotalRecords,
                            new: item.database.newRecords,
                            updated: item.database.updatedRecords,
                            deleted: item.database.deletedRecords + item.database.purgedRecords
                        }
                    });
                    return {
                        ...listCopy,
                        history: history.data,
                        chartData,
                        hasDelta
                    }
                }));

                setLists(allHistory);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getLists().catch((e: any) => { console.log(e) });

    }, [targetEnv, dateStart, dateEnd]);

    return { lists, loading, error };
}
