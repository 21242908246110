import '../../assets/scss/NavBar.scss';

import { ShowIfAccess, useOidc, useOidcAccessToken, useOidcUser } from '../../utils/secure';
import { useContext, useEffect, useState } from 'react';

import AccountMenu from './AccountMenu';
import { EnvContext } from '../../contexts/EnvironmentContext';
import HorizontalMenu from './HorizontalMenu';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './../../assets/img/neteriumLogoFull.svg';
import { Tooltip } from 'antd';
import { callBackEnd } from '../../utils/backEndUtils';

export default function NavBar() {

    const { isAuthenticated, renewTokens } = useOidc();
    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [namespace, setNamespace] = useState<Namespace | null>(null);
    const env = useContext(EnvContext);

    const refreshToken = async () => {
        await renewTokens();
        window.location.assign(window.location.origin);
    }

    useEffect(() => {
        async function getNamespace() {
            try {
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${accessTokenPayload?.namespaceId}`);
                setNamespace(response);

            } catch (error: any) {
                console.log(error);
            }
        }

        if (accessTokenPayload?.namespaceId) {
            getNamespace().catch((e: any) => console.log(e));
        }
    }, [oidcUser, isAuthenticated, accessToken, accessTokenPayload]);

    return (
        <>
            <nav className={`navbar ${oidcUser && env}`}>
                <div className="topBar">
                    <div className="logo">
                        <Link to={'/'} style={{ display: 'flex' }}>
                            <Logo onClick={refreshToken} />
                        </Link>
                    </div>
                    <div className="tRContent">
                        {(namespace !== null && namespace.label !== '' && namespace?.label?.length > 0) &&
                            <Tooltip title={"You are a member of the " + namespace.label + " Namespace"}>
                                <span className="namespace">{namespace?.label}</span>
                            </Tooltip>
                        }
                        <ul className="links">
                            <ShowIfAccess authenticated={true}>
                                <li><AccountMenu /></li>
                            </ShowIfAccess>
                        </ul>
                    </div>
                </div>
                <HorizontalMenu />
            </nav>
        </>
    );
}