export const getEfficiencyScore = (perfect: number, high: number, medium: number, low: number): number => {
        const total: number = perfect + high + medium + low;
        let score: number = 0;
        if (total === 0) {
                return 0;
        }
        else if (perfect > 0 && high === 0 && medium === 0 && low === 0) {
                score = (perfect * 4) / (total * 4);
        }
        else {
                score = (((perfect * 4) + (high * 3) + (medium * 2) + (low * 1)) / (total * 4))
        }
        return (Math.round((Math.round(score * 100) / 100) * 100) * 100) / 100;
}