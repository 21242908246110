import { Alert, Badge, Button, Flex, Space } from "antd";
import { useContext, useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import { EnvContext } from "../../contexts";
import { SecureWithLogin } from "../../utils/secure";
import { callBackEnd } from "../../utils/backEndUtils";
import { json } from "@codemirror/lang-json";

interface TryItDeveloperProps {
    requestCode: any;
    responseCode: any;
    setRequestCode: (code: any) => void;
    setResponseCode: (code: any) => void;
    defaultRequestCode: any;
}



const TryItDeveloper: React.FC<TryItDeveloperProps> = ({ requestCode, setRequestCode, setResponseCode }) => {
    const env: string = useContext(EnvContext);
    const [isQueryValid, setIsQueryValid] = useState<boolean>(true);
    const [jsonValidRibbon, setJsonValidRibbon] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [code, setCode] = useState<string>(JSON.stringify(requestCode, null, 2));

    const handleOnChangeCode = (value: string) => {
        setCode(value);
        const isValid: boolean = isValidJSON(value);
        setJsonValidRibbon(isValid);
        if (isValid) {
            const jsonValue: any = JSON.parse(value);
            setIsQueryValid(jsonValue?.records?.length > 0);
        }
        setRequestCode(JSON.parse(value));
    }

    const isValidJSON = (txt: string) => {
        if (txt.length > 0) {
            try {
                JSON.parse(txt);

                return true;
            } catch {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const handleForm = async () => {
        setLoading(true);

        try {
            //Call the backend
            const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
            const response = await callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, code)
            setResponseCode(response);
            setLoading(false);
            setError('');
        }
        catch (error: any) {
            console.error("Error in handleForm", error);
            setLoading(false);
            setError(error.toString());
            setLoading(false);
            setResponseCode(error);
        }
    }

    return (

        <SecureWithLogin role={['js_screen']} module='TRY_IT' callbackPath="/tryIt" namespaceRequired={true}>
            <Flex vertical gap="middle">

                <Badge.Ribbon text={jsonValidRibbon ? "Valid JSON" : "Invalid JSON"} color={jsonValidRibbon ? "green" : "red"}>
                    <CodeMirror
                        value={JSON.stringify(requestCode, null, 2)}
                        // minHeight='300px'
                        style={{ border: jsonValidRibbon ? '2px solid rgb(240, 240, 240)' : '2px solid red', borderRadius: '2px', }}
                        onChange={handleOnChangeCode}
                        onLoad={() => { handleOnChangeCode(JSON.stringify(requestCode, null, 2)) }}
                        extensions={[json()]}
                        height="240px"
                    />
                </Badge.Ribbon>

                {!isQueryValid &&
                    <Alert message="Please make sure the JSON is valid and the records array exists and is not empty" type="warning" showIcon />
                }
                {error !== '' &&
                    <Alert message={error.toString()} type="warning" showIcon />
                }
                <Flex justify="end">
                    <Space>
                        <Button type="primary" disabled={!jsonValidRibbon || loading || !isQueryValid} onClick={handleForm}>Execute</Button>
                    </Space>
                </Flex>
            </Flex>
        </SecureWithLogin >
    )
}
export default TryItDeveloper;