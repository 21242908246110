import './assets/scss/App.scss';

import { AnimatePresence, MotionConfig } from 'framer-motion';
import { App, ConfigProvider, Modal, notification } from 'antd';
import {
    Navigate,
    Route,
    Routes,
    useNavigate
} from "react-router-dom";
import { useContext, useState } from 'react';

import Account from './components/account/Account';
import AppLayout from './components/Layout/AppLayout';
import Authenticating from './components/login/Authenticating';
import AuthenticationError from './components/login/AuthenticatingError';
import AuthenticationSuccess from './components/login/AuthenticationSuccess';
import ClusterStatus from './components/clusterStatus/ClusterStatus';
import { ConfigContext } from './contexts/ConfigContext';
import Dashboard from './components/dashboard/Dashboard';
import Doc from './components/doc/Doc';
import { EnvironmentContextProvider } from './contexts/EnvironmentContext';
import Error404Page from './components/Layout/Error404Page';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Faq from './components/faq/Faq';
import FormNamespace from './components/crm/namespaces/FormNamespace';
import FormPackages from './components/crm/packages/FormPackages';
import FormRoles from './components/crm/users/FormRoles';
import FormServiceAccount from './components/nsadmin/nsadminComponents/serviceAccounts/FormServiceAccount';
import FormSetNamespace from './components/crm/users/FormSetNamespace';
import FormUsers from './components/crm/users/FormUsers';
import Home from './components/Home';
import JoinNamespace from './components/join/JoinNamespace';
import ListStatus from './components/listStatus/ListStatus';
import LoadingSkeleton from './components/Layout/LoadingSkeleton';
import LoginEmail from './components/login/LoginEmail';
import ModelValidation from './components/modelValidation/ModelValidation';
import NSAInviteForm from './components/nsadmin/nsadminComponents/users/NSAInviteForm';
import NSALayout from './components/Layout/NSALayout';
import NSAPackage from './components/nsadmin/nsadminComponents/packages/NSAPackage';
import NSAPackages from './components/nsadmin/nsadminComponents/packages/NSAPackages';
import NSAUser from './components/nsadmin/nsadminComponents/users/NSAUser';
import NSAUserFormRoles from './components/nsadmin/nsadminComponents/users/NSAUserFormRoles';
import NSAUsers from './components/nsadmin/nsadminComponents/users/NSAUsers';
import NSAdminServiceAccounts from './components/nsadmin/nsadminComponents/serviceAccounts/NSAdminServiceAccounts';
import Namespace from './components/crm/namespaces/Namespace';
import Namespaces from './components/crm/namespaces/Namespaces';
import News from './components/news/News';
import NewsPage from './components/news/NewsPage';
import { OidcProvider } from "@axa-fr/react-oidc";
import PrivacyStatement from './components/legal/PrivacyStatement';
import ProfileDetails from './components/profile/ProfileDetails';
import ReleaseNotes from './components/releaseNotes/ReleaseNotes';
import RolesContextProvider from './contexts/RolesContext';
import SessionLost from './components/login/SessionLost';
import SetAuthentication from './components/nsadmin/nsadminComponents/serviceAccounts/SetAuthentication';
import Stats from './components/stats/Stats';
import SupportHome from './components/support/SupportHome';
import SupportTicket from './components/support/SupportTicket';
import TryItHome from './components/tryIt/TryItHome';
import Upgradenamespace from './components/crm/namespaces/Upgradenamespace';
import User from './components/crm/users/User';
import UserGuide from './components/userGuide/UserGuide';
import Users from './components/crm/users/Users';
import { callBackEnd } from './utils/backEndUtils';

function Application() {
    const configContext = useContext(ConfigContext);

    if (!configContext) {
        throw new Error('Error while loading the configuration');
    }
    const { config, loading: loadingConfigContext } = configContext;

    notification.config({
        placement: 'topRight',
        showProgress: true,
    });
    //set global variables

    (window as any).portalBackEndUrl = config?.portalBackEndUrl;
    
    const configuration = {
        client_id: "api",
        redirect_uri: window.location.origin + '/authentication/callback',
        silent_redirect_uri: window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
        scope: "openid profile roles",
        authority: config?.kcAuthority ?? "",
        service_worker_relative_url: '/OidcServiceWorker.js',
        service_worker_only: true,
        // extras: {
        //     id_token_hint: 'undefined',
        //     client_id: "api",
        // }
    };

    const navigate = useNavigate();
    const [modalInvitationRequired, setModalInvitationRequired] = useState<boolean>(false);

    const onEvent = async (configurationName: any, eventName: any, data: any) => {
        if (eventName === 'token_aquired' || eventName === 'token_acquired') {
            const response: any = await callBackEnd('portal', 'PATCH', `users/login`).catch((e: any) => console.log(e.status));

            if (response?.status === 'NAMESPACE_EXISTS') {
                navigate(`/join/${response?.namespaceId}`);
            } else if (response?.status === 'INVITATION_REQUIRED') {
                setModalInvitationRequired(true);
            }
        }
    };

    return (
        <div className="app">
            <App>
                {loadingConfigContext ? <LoadingSkeleton /> :
                    <EnvironmentContextProvider value={{ environment: "SANDBOX" }}>
                        <OidcProvider
                            configuration={configuration}
                            configurationName='config'
                            onEvent={onEvent}
                            loadingComponent={Authenticating}
                            authenticatingComponent={Authenticating}
                            callbackSuccessComponent={AuthenticationSuccess}
                            authenticatingErrorComponent={AuthenticationError}
                            // onSessionLost={SessionLost}
                            sessionLostComponent={SessionLost}
                        >
                            <RolesContextProvider>
                                <AnimatePresence>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Menu: {
                                                    darkItemColor: "rgba(255, 255, 255, 0.9)",
                                                    darkItemHoverBg: "rgba(255, 255, 255, 0.2)",
                                                    darkItemSelectedBg: "rgba(255, 255, 255, 0.2)",
                                                }
                                            },
                                            token: {
                                                colorPrimary: '#334256',
                                                colorBgBase: "#ffffff",
                                            },
                                        }}
                                    >
                                        <MotionConfig transition={
                                            { type: "spring", stiffness: 300, damping: 30 }
                                        }>
                                            <Routes>
                                                <Route path="/" element={<AppLayout />}>
                                                    <Route index element={<Home />} />
                                                    <Route path="documentation">
                                                        <Route path="api-documentation" element={<Doc />} />
                                                        <Route path="release-notes/:id?" element={<ReleaseNotes />} />
                                                    </Route>
                                                    <Route path="status">
                                                        <Route path="dashboard" element={<Dashboard />} />
                                                        <Route path="cluster-status" element={<ClusterStatus />} />
                                                        <Route path="list-status" element={<ListStatus />} />
                                                        <Route path="stats" element={<Stats />} />
                                                    </Route>
                                                    <Route path="privacy-statement" element={<PrivacyStatement />} />
                                                    <Route path="account" element={<Account />} />
                                                    <Route path="news/">
                                                        <Route index element={<News />} />
                                                        <Route path=":newsId" element={<NewsPage />} />
                                                    </Route>
                                                    <Route path="namespaces">
                                                        <Route index element={<Namespaces />} />
                                                        <Route path="create" element={<FormNamespace />} />
                                                        <Route path=":namespaceId" element={<Namespace />} />
                                                        <Route path="edit/:namespaceId" element={<FormNamespace />} />
                                                        <Route path="upgrade/:namespaceId" element={<Upgradenamespace />} />
                                                        <Route path=":namespaceId/packages/create" element={<FormPackages />} />
                                                        <Route path=":namespaceId/packages/:packageId" element={<FormPackages />} />
                                                    </Route>
                                                    <Route path="user-guide/:type" element={<UserGuide />} />
                                                    <Route path="faq" element={<Faq />} />
                                                    <Route path="getting-started">
                                                        <Route path="try-the-api" element={<TryItHome />} />
                                                    </Route>
                                                    <Route path="support" element={<SupportHome />} />
                                                    <Route path="support/:ticketId" element={<SupportTicket />} />
                                                    <Route path="tools">
                                                        <Route path="model-validation" element={<ModelValidation />} />
                                                    </Route>
                                                    <Route path="profileDetails/:profileId" element={<ProfileDetails />} />
                                                    <Route path="users/">
                                                        <Route index element={<Users />} />
                                                        <Route path=":userId" element={<User />} />
                                                        <Route path="create" element={<FormUsers />} />
                                                        <Route path="edit/:userId" element={<FormUsers />} />
                                                        <Route path=":userId/:namespaceId/roles" element={<FormRoles />} />
                                                        <Route path="namespace/:userId/" element={<FormSetNamespace />} />
                                                    </Route>
                                                    <Route path="login/">
                                                        <Route index element={<LoginEmail />} />
                                                        <Route path="namespace/:namespace" element={<LoginEmail />} />
                                                    </Route>
                                                    <Route path="nsadmin/" element={<NSALayout />}>
                                                        <Route path="" element={<Navigate replace to="packages/" />} />
                                                        <Route path=":namespaceId/users/:userId" element={<NSAUser />} />
                                                        <Route path=":namespaceId/users/invite" element={<NSAInviteForm />} />
                                                        <Route path=":namespaceId/users/:userId/roles" element={<NSAUserFormRoles />} />
                                                        <Route path=":namespaceId/users/:userId/roles/:action" element={<NSAUserFormRoles />} />
                                                        <Route path="service-accounts/" element={<NSAdminServiceAccounts />} />
                                                        <Route path="users/" element={<NSAUsers />} />
                                                        <Route path="packages/" element={<NSAPackages />} />
                                                        <Route path=":namespaceId/packages/:packageId" element={<NSAPackage />} />
                                                        <Route path=":namespaceId/service-accounts/create" element={<FormServiceAccount />} />
                                                        <Route path=":namespaceId/service-accounts/:serviceAccountId" element={<FormServiceAccount />} />
                                                        <Route path=":namespaceId/service-accounts/:serviceAccountId/authentication" element={<SetAuthentication />} />
                                                    </Route>
                                                    <Route path="join/:namespaceId" element={<JoinNamespace />} />
                                                    <Route path="*" element={<Error404Page />} />
                                                </Route>
                                            </Routes>
                                        </MotionConfig>
                                    </ConfigProvider>
                                </AnimatePresence>
                            </RolesContextProvider>
                            <Modal
                                title={<><ExclamationCircleFilled style={{ color: "#ff0000" }} /> Invitation required</>}
                                centered
                                open={modalInvitationRequired}
                                onOk={() => setModalInvitationRequired(false)}
                                onCancel={() => setModalInvitationRequired(false)}
                                footer={null}
                                closable={true}
                            >
                                <p>We were not able to identify the namespace you want to join based on your email domain. Please contact your namespace administrator to receive an invitation.</p>
                            </Modal>
                        </OidcProvider>
                    </EnvironmentContextProvider>
                }
            </App>
        </div >
    );
}

export default Application;
