import { useEffect, useState } from 'react';

import { useOidcAccessToken } from '../utils/secure';

export default function useAllEnvironments() {
    const { accessTokenPayload } = useOidcAccessToken();
    const [allEnv, setAllEnv] = useState<{name:string,label:string,color:string}[]>([]);

    const audience: string[] | null = (accessTokenPayload?.aud) ? accessTokenPayload.aud : null;

    useEffect(() => {
        let env: any[] = [];
        if (audience !== null) {
            if (audience?.includes('dev')) {
                env.push({
                    name: 'DEV',
                    label: 'Dev',
                    color: '#38bdf8'
                })
            }
            if (audience?.includes('sandbox')) {
                env.push({
                    name: 'SANDBOX',
                    label: 'Sandbox',
                    color: '#22c55e'
                })
            }
            if (audience?.includes('test')) {
                env.push({
                    name: 'TEST',
                    label: 'Test',
                    color: '#f59e0b'
                })
            }
            if (audience?.includes('on_premise')) {
                env.push({
                    name: 'ON_PREMISE',
                    label: 'On Premise',
                    color: '#900093'
                })
            }
            if (audience?.includes('prod')) {
                env.push({
                    name: 'PROD',
                    label: 'Production',
                    color: '#c8403d'
                })
            }
            setAllEnv(env);
        }

    }, [audience])

    return { allEnv };
}
