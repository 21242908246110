import '../../assets/scss/ErrorPage.scss';

import { Button, Flex, Result } from 'antd';

import { Link } from 'react-router-dom';
import MotionBox from './Animated/MotionBox';

export default function Error404Page() {
    return (
        <MotionBox>
            <Flex justify="center" align="center" vertical gap="large">
                <Result
                    status="404"
                    title="Page not found"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                        <Link to="/">
                            <Button type="primary">Go back to homepage</Button>
                        </Link>
                    }
                />
            </Flex>
        </MotionBox>
    );
}
