import { Col, DescriptionsProps, Flex, Row, Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import NSAPackageVolumes from "../../NSAPackageVolumes";
import useAllEnvironments from "../../../../../hooks/useAllEnvironments";
import useNamespaceById from "../../../../../hooks/useNamespaceById";

const SAPackageInfos = (props: any) => {
    const { getNamespacePackage } = useNamespaceById();
    const { allEnv } = useAllEnvironments();
    const { packageId, clientId } = props;
    const [pkg, setPkg] = useState<any>(null);

    const SAPackageInfosItems: DescriptionsProps['items'] = [
        { label: "Name", children: <Tooltip title="Click to view the Package details"><Link to={`/nsadmin/packages`}>{pkg?.name}</Link></Tooltip> },
        {
            label: "Environment", children: allEnv.map((e: any) => {
                if (e.name === pkg?.environment) {
                    return <Tag key={e.name} color={e.color}>{e.label}</Tag>
                }
                return null;
            })
        },
        { label: "Expiration date", children: pkg?.expiration ? pkg?.expiration : 'No expiration date' },
    ];


    useEffect(() => {
        async function getVolumes() {
            try {
                const response: any = await getNamespacePackage({ packageId });
                setPkg(response);
                console.log(response)
            } catch (error: any) {
                throw new Error(error);
            }
        }
        packageId && getVolumes();
    }, [getNamespacePackage, packageId]);

    return (
        pkg && <Flex vertical gap="large">
            <Space size="middle">

            </Space>
            <Row gutter={[12, 12]}>
                {SAPackageInfosItems.map((item, index) => (
                    <Col key={index} xs={24} md={12} xxl={6}>
                        <span style={{ color: 'rgba(0,0,0,0.45)', marginBottom: '1rem' }}>{item.label}:{' '}</span>{item.children}
                    </Col>
                ))}
                <Col xs={24} md={12} xxl={6}>
                    <span style={{ color: 'rgba(0,0,0,0.45)', marginBottom: '1rem' }}>Client ID:{' '} </span>{clientId}
                </Col>
            </Row>
            <NSAPackageVolumes pkg={pkg} />
        </Flex>
    );
};
export default SAPackageInfos;