import { Alert, Col, Flex, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';

import CountUp from 'react-countup';
import { Loader } from '../Loader/Loader';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { getIntervalStats } from '../../utils/statsUtils';
import { toTitleCase } from '../../utils/stringUtils';

type Props = {frequency:string, data:any, loading:boolean, error:any, product:string};

export const ProductStatsWidget = ({ frequency, data, loading, error, product}: Props) => {


  const stats = getIntervalStats(data, frequency);
  const [value , setValue] = useState(stats.reduce((acc: any, cur: any) => acc + cur.counters[product as keyof typeof cur.counters], 0));


  useEffect(() => {
    setValue(stats.reduce((acc: any, cur: any) => acc + cur.counters[product as keyof typeof cur.counters], 0));
  },[product, stats]);
  const getFrequencyString = (frequency: string) => {
    switch (frequency) {
      case 'daily':
        return `Today:`;
      case 'monthly':
        return `Last 30 Days:`;
      case 'yearly':
        return `This year:`;
      default:
        return '';
    }
  };

  const title = getFrequencyString(frequency);

  if(loading){
    return (
      <Loader />
    )
  }

  return (
    <WidgetCard
      className="card daily-stats-widget-card"
    >
      {error ? (
        <Alert
          message="Error"
          description={error.toString()}
          type="error"
          showIcon
        />
      ) : (
        <Flex vertical>
          <Flex justify="space-between">
            <Typography.Text className="text-capitalize m-0">
              {title}
            </Typography.Text>
            {/* <Space direction="vertical">
              <Switch style={{ backgroundColor: `var(--color${toTitleCase(product)})` }} checkedChildren="JS" unCheckedChildren="JF" defaultChecked className='project-stats-widget' onClick={() => toggleProduct(product)}/>
            </Space> */}
          </Flex>
        <Row>
          <Col>
            <Typography.Title level={2} style={{ color: `var(--color${toTitleCase(product)})` }}>
              {value && typeof value === 'number' ? (
                <>
                  {<CountUp end={value} duration={0.5}/>}
                </>
              ) : (
                value
              )}
            </Typography.Title>
          </Col>

          {/* <Col span={10}>
            <ColumnChart data={data} />
          </Col> */}

        </Row>
        {/* <Flex align="center">
          <Tag color={diff < 0 ? 'red' : 'green'}>{diff}%</Tag>
          <Typography.Text>compared to last month.</Typography.Text>
        </Flex> */}
      </Flex>
      )}
    </WidgetCard>
  );
};