import { Button, Card, Descriptions, Space } from 'antd';
import { HomeFilled, HomeOutlined } from '@ant-design/icons';
import { SecureWithLogin, useOidcAccessToken } from "../../utils/secure";
import { useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Moment from 'react-moment';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ShowError from '../Layout/ShowError';
import { callBackEnd } from '../../utils/backEndUtils';
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function Account() {
    const { accessTokenPayload } = useOidcAccessToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [user, setUser] = useState<UserProfile | null>(null);

    useEffect(() => {

        async function getUser() {
            setLoading(true);
            try {
                const response: any = await callBackEnd<UserProfile>('portal', 'GET', `me`);
                setUser(response);
                setLoading(false);
            } catch (error: any) {
                console.log(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getUser().catch((error) => { console.log(error) });
    }, []);

    return (

        <SecureWithLogin callbackPath="/account" role={[]}>
            <MotionBox>
                <PageHeader icon={<HomeFilled />} title="My Account" subtitle='Portal' breadcrumbs={[{ title: <span><HomeOutlined />&nbsp;My Account</span>, href: '/account', }]} />
                <Content>
                    <Card
                        extra={
                            <Space size={"small"}>
                                {(accessTokenPayload && accessTokenPayload?.iss) &&
                                    <a href={`${accessTokenPayload.iss}/account`} target="_blank" rel="noreferrer">
                                        <Button type="primary" icon={<FontAwesomeIcon icon={faPen}></FontAwesomeIcon>}>Edit my account</Button>
                                    </a>
                                }
                            </Space>
                        }
                    >
                        {/* {(!loading && error === null && user !== null && !isEditing) ? */}
                        {(!loading && error === null && user !== null) ?
                            <Descriptions>
                                <Descriptions.Item label="First Name">{user?.firstName}</Descriptions.Item>
                                <Descriptions.Item label="Last Name">{user?.lastName}</Descriptions.Item>
                                <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
                                <Descriptions.Item label="Company">{user?.company}</Descriptions.Item>
                                <Descriptions.Item label="Enablement">{(user?.enabled) ? "Enabled" : "Disabled"}</Descriptions.Item>
                                <Descriptions.Item label="Status">{user?.status}</Descriptions.Item>
                                <Descriptions.Item label="Last Login"><Moment format="YYYY/MM/DD h:mm:ss a">{user.lastLogin}</Moment></Descriptions.Item>
                                <Descriptions.Item label="UID">{user?.uid}</Descriptions.Item>
                            </Descriptions>
                            :
                            <>
                                {loading && <LoadingSkeleton />}
                                {error && <ShowError error={error.toString()} />}
                            </>
                        }

                    </Card>
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
