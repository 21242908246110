import './../../assets/scss/OidcComponent.scss';

import { Spin } from 'antd'

export default function Authenticating() {
    return (
        <div className="oidcComponent">
            <Spin tip="Loading Portal" size="large">
                <div className="content" />
            </Spin>
        </div>

    )
}
