import moment from "moment";

export const getIntervalStats = (stats: any, cycle: string): any[] => {
    let toReturn: any;
    switch (cycle) {
        case 'daily':
            toReturn = stats.filter((stat: any) => moment(stat.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));
            break;
        case 'monthly':
            toReturn = stats.filter((stat: any) => moment(stat.date).format('YYYY-MM') === moment().format('YYYY-MM'));
            break;
        case 'yearly':
            toReturn = stats.filter((stat: any) => moment(stat.date).format('YYYY') === moment().format('YYYY'));
            break;
        default:
            toReturn = stats;
            break;
    }
    return toReturn;
}

export const getTotalValue = (statsArray: any, product: string): number => {
    let total: number = 0;
    for (const stat of statsArray) {
        total += stat.counters[product.toLowerCase() as keyof typeof stat];
    }
    return total;
}

export const getMaxValue = (statsArray: any, product: string): number => {
    let groupedByDays: any = {};
    //Grouping by days no matter the collection
    statsArray.forEach((stats: any) => {
        if (groupedByDays[stats.date]) {
            groupedByDays[stats.date] += stats.counters[product as keyof typeof stats];
        } else {
            groupedByDays[stats.date] = stats.counters[product as keyof typeof stats];
        }
    });
    //Find max
    let max = 0;
    Object.entries(groupedByDays).forEach((stats: any) => {
        if (stats[1] > max) {
            max = stats[1];
        }
    });
    return max;
}
export const getAverageValue = (statsArray: any, product: string): number => {
    let arr: Array<number> = [];
    for (const stat of statsArray) {
        arr.push(stat.counters[product as keyof typeof stat]);
    }
    const average = arr.reduce((p, c) => p + c, 0) / arr.length;
    return Math.round(average);
}