import { Card, Statistic } from "antd";

export default function TotalBox(props: { stats: any, product: string, title: string }) {

    const { stats, product, title } = props;
    const total: any = stats.reduce((acc: any, cur: any) => acc + cur.counters[product as keyof typeof cur.counters], 0);

    return (

        <Card type="inner">
            <Statistic
                title={title}
                value={total}
                precision={0}
                valueStyle={{ color: '#82b9d6' }}
                groupSeparator=''
                decimalSeparator=','
            />
        </Card>

    )
}
