import { Progress, ProgressProps } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { getTotalValue } from '../../utils/statsUtils';
import usePackageStat from '../../hooks/usePackageStat';

export default function VolumeProgress({ pack, volume, type, size }: { readonly pack: Package, readonly volume: any, readonly type: "circle" | "line" | "dashboard", size?: number }) {
    const { stats, loading, error } = usePackageStat(pack, volume);

    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#87d068',
        '100%': '#f50',
    };

    const getPercent = ((): number => {
        if (stats !== null && stats?.length > 0) {
            return (Math.round((getTotalValue(stats, volume.product.toLowerCase()) / volume?.amount) * 1000)) / 10
        }
        return 0;
    })

    return (
        <>
            {(!error && !loading && stats && stats.length > 0) &&
                <Progress
                    percent={getPercent()}
                    strokeColor={twoColors}
                    // size={"default"}
                    size={size ?? "small"}
                    status="active"
                    type={type}
                />
            }
            {(stats === null && error === null) && //No stats for this volume
                <Progress
                    percent={0}
                    strokeColor={twoColors}
                    size={size ?? "small"}
                    status="active"
                    type={type}
                />
            }
            {loading && <LoadingOutlined size={48} />}
            {(error) && <>No stats available</>}

        </>
    )
}