import { Card, CardProps } from 'antd';

import { ReactNode } from 'react';

type Props = { children: ReactNode } & CardProps;

export const WidgetCard = ({ children, ...others }: Props) => {
    return (
        <Card {...others}>
            {children}
        </Card>
    );
};