import './../../assets/scss/OidcComponent.scss';

import { Result } from 'antd'

export default function AuthenticationSuccess() {
    return (
        <div className="oidcComponent">
            <Result
                status="success"
                title="Successfully Authenticated"
                subTitle="You can now access the Neterium User Portal"
            />
        </div>
    )
}
