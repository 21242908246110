import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
export default function RadarChartCompareCustom(props: { chartData: any[] }) {
    const colors: string[] = [
        "hsla( 209, 100%, 40%,.5)",
        "hsla( 335, 100%, 40%,.5)",
        "hsla( 145, 100%, 40%,.5)",
        "hsla( 37, 100%, 40%,.5)",
        "hsla( 278, 100%, 40%,.5)",
        "hsla( 79, 100%, 40%,.5)",
        "hsla( 306, 100%, 40%,.5)",
        "hsla( 21, 100%, 40%,.5)",
        "hsla( 237, 100%, 40%,.5)",
        "hsla( 350, 100%, 40%,.5)",
        "hsla( 152, 100%, 40%,.5)",
        "hsla( 34, 100%, 40%,.5)",
        "hsla( 87, 100%, 40%,.5)",
        "hsla( 304, 100%, 40%,.5)",
        "hsla( 38,0%, 40%,.5)",
        "hsla( 228, 100%, 40%,.5)",
        "hsla( 267, 100%, 40%,.5)",
        "hsla( 202, 100%, 40%,.5)",
        "hsla( 115, 100%, 40%,.5)",
        "hsla( 237, 100%, 40%,.5)",
        "hsla( 233, 100%, 40%,.5)",
        "hsla( 274, 100%, 40%,.5)",
        "hsla( 218, 100%, 40%,.5)",
        "hsla( 328, 100%, 40%,.5)",
        "hsla( 243, 100%, 40%,.5)",
        "hsla( 232, 100%, 40%,.5)",
        "hsla( 218, 100%, 40%,.5)",
        "hsla( 45, 100%, 40%,.5)",
        "hsla( 223, 100%, 40%,.5)",
        "hsla( 111, 100%, 40%,.5)",
        "hsla( 107, 100%, 40%,.5)",
        "hsla( 57, 100%, 40%,.5)",
        "hsla( 299, 100%, 40%,.5)",
        "hsla( 56, 100%, 40%,.5)",
        "hsla( 176, 100%, 40%,.5)",
        "hsla( 122, 100%, 40%,.5)",
        "hsla( 36, 100%, 40%,.5)",
        "hsla( 62, 100%, 40%,.5)",
        "hsla( 225, 100%, 40%,.5)",
        "hsla( 110, 100%, 40%,.5)",
        "hsla( 96, 100%, 40%,.5)",
        "hsla( 357, 100%, 40%,.5)",
        "hsla( 93, 100%, 40%,.5)",
        "hsla( 102, 100%, 40%,.5)",
        "hsla( 88, 100%, 40%,.5)",
        "hsla( 62, 100%, 40%,.5)",
        "hsla( 188, 100%, 40%,.5)",
        "hsla( 158, 100%, 40%,.5)",
        "hsla( 226, 100%, 40%,.5)",
        "hsla( 103, 100%, 40%,.5)",
        "hsla( 282, 100%, 40%,.5)",
        "hsla( 70, 100%, 40%,.5)",
        "hsla( 150, 100%, 40%,.5)",
        "hsla( 202, 100%, 40%,.5)",
        "hsla( 75, 100%, 40%,.5)",
        "hsla( 238, 100%, 40%,.5)",
        "hsla( 50, 100%, 40%,.5)",
        "hsla( 188, 100%, 40%,.5)",
        "hsla( 148, 100%, 40%,.5)",
        "hsla( 279, 100%, 40%,.5)",
        "hsla( 63, 100%, 40%,.5)",
        "hsla( 271, 100%, 40%,.5)",
        "hsla( 230, 100%, 40%,.5)",
        "hsla( 230, 100%, 40%,.5)",
        "hsla( 313, 100%, 40%,.5)",
        "hsla( 275, 100%, 40%,.5)",
        "hsla( 307, 100%, 40%,.5)",
        "hsla( 343, 100%, 40%,.5)",
        "hsla( 39, 100%, 40%,.5)",
        "hsla( 179, 100%, 40%,.5)",
        "hsla( 273, 100%, 40%,.5)",
        "hsla( 230, 100%, 40%,.5)",
        "hsla( 284, 100%, 40%,.5)",
        "hsla( 103, 100%, 40%,.5)",
        "hsla( 40, 100%, 40%,.5)",
        "hsla( 317, 100%, 40%,.5)",
        "hsla( 229, 100%, 40%,.5)",
        "hsla( 75, 100%, 40%,.5)",
        "hsla( 185, 100%, 40%,.5)",
        "hsla( 189, 100%, 40%,.5)",
        "hsla( 240, 100%, 40%,.5)",
        "hsla( 335, 100%, 40%,.5)",
        "hsla( 140, 100%, 40%,.5)",
        "hsla( 326, 100%, 40%,.5)",
        "hsla( 321, 100%, 40%,.5)",
        "hsla( 112, 100%, 40%,.5)",
        "hsla( 117, 100%, 40%,.5)",
        "hsla( 241, 100%, 40%,.5)",
        "hsla( 279, 100%, 40%,.5)",
        "hsla( 99, 100%, 40%,.5)",
        "hsla( 176, 100%, 40%,.5)",
        "hsla( 339, 100%, 40%,.5)",
        "hsla( 232, 100%, 40%,.5)",
        "hsla( 132, 100%, 40%,.5)",
        "hsla( 290, 100%, 40%,.5)",
        "hsla( 336, 100%, 40%,.5)",
        "hsla( 98, 100%, 40%,.5)",
        "hsla( 352, 100%, 40%,.5)",
        "hsla( 119, 100%, 40%,.5)",
        "hsla( 164, 100%, 40%,.5)",
    ]

    const getColor: any = (index: number) => {

        if (colors[index] === undefined) {
            return colors[index - 100];
        }
        return colors[index];

    }

    let categories: string[] = [];

    if (props.chartData[0]?.effectiveness) {
        categories = props.chartData[0]?.effectiveness.map((data: any) => data.category);
    }

    const formattedDatas: any[] = categories.map((c: any) => {
        let toReturn: any = {
            category: c,
            fullMark: 100
        }
        props.chartData.forEach((data: any, index: number) => {
            toReturn[`run${index + 1}`] = data.effectiveness.find((e: any) => e.category === c).value;
        })

        return toReturn;
    })

    
    // const generatePastelColor = (): number => {
    //     return Math.floor(Math.random() * 360);
    // }

    return (
        <>
            {(props?.chartData !== undefined && props?.chartData[0]?.effectiveness.length > 2) ?
                <div className="chartContainer">
                    <ResponsiveContainer width="100%" height="100%">
                        <RadarChart
                            // cx={400}
                            // cy={300}
                            // outerRadius={150}
                            // width={800}
                            // height={600}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                            data={formattedDatas}
                        >

                            <PolarGrid />
                            <PolarAngleAxis dataKey="category" />
                            <PolarRadiusAxis />
                            <Tooltip />
                            <Legend />
                            {props.chartData.map((data: any, index: number) => {
                                return <Radar
                                    key={index}
                                    name={`Run ${index + 1}`}
                                    dataKey={`run${index + 1}`}
                                    fill={getColor(index)}
                                    fillOpacity={0.6}
                                />
                            })}
                        </RadarChart>
                    </ResponsiveContainer>
                </div>
                : <p>Not enough data to create a chart. Please add at least 3 categories in your records</p>}
        </>
    )
}
