import { useEffect, useState } from 'react';

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import { Table } from "antd";
import { getCountryByValue } from '../../utils/geoInfos';

export default function TopCountries(props: { topCountries: Promise<any> }) {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await props.topCountries;
                if (result.length > 0) {
                    setData(result);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.topCountries]);


    const columns = [
        {
            title: 'Country',
            dataIndex: 'value',
            key: 'country',
            render: (value: string) => getCountryByValue(value)?.label,
            sorter: (a: any, b: any) => a.value.localeCompare(b.value),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            sorter: (a: any, b: any) => a.category.localeCompare(b.category),
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: any, b: any) => a.count - b.count,
            defaultSortOrder: 'descend' as const
        }
    ];


    return loading ? <LoadingSkeleton /> : <Table size="small" dataSource={data} pagination={false} columns={columns} scroll={{ x: 'max-content' }} />;

}
