import '../../../assets/scss/Namespaces.scss';

import { Content } from 'antd/es/layout/layout';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../../Layout/LoadingSkeleton';
import MotionBox from '../../Layout/Animated/MotionBox';
import NamespacesList from './NamespacesList';
import { PageHeader } from '../../Layout/header/PageHeader';
import { SecureWithLogin } from '../../../utils/secure';
import ShowError from '../../Layout/ShowError';
import useNamespaces from '../../../hooks/useNamespaces';

export default function Namespaces() {
    const { namespaces, loading: namespacesLoading, error: namespacesError }: { namespaces: Namespace[], loading: boolean, error: any } = useNamespaces();

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_namespaces']} namespaceRequired={true}>
            <MotionBox>
                <Content>
                <PageHeader
                title='All Namespaces'
                subtitle='Access and Enablement'
                breadcrumbs={[
                    {title:<span><HomeOutlined/>&nbsp;Home</span>,href: '/',},
                    {title:<span>All namespaces</span>}]}
            />
                {(!namespacesLoading && namespacesError === null) ?
                    <NamespacesList
                        title='Namespaces'
                        namespaces={namespaces} />
                    :
                    <>
                        {namespacesLoading && <LoadingSkeleton />}
                            {namespacesError && <ShowError error={namespacesError.toString()} />}
                    </>
                }
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
