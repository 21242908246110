import { callBackEnd } from "./backEndUtils";
import { notification } from "antd";

export const getAsyncPackageTypes = async (): Promise<StaticPackageType[]> => {
    let packageTypes: StaticPackageType[] = [];
    try {
        packageTypes = await callBackEnd('portal', 'GET', 'static/package_types') as StaticPackageType[];
        return packageTypes;
    }
    catch (error: any) {
        notification.error({
            message: 'Error',
            description: 'Failed to get package types'
        });
        return [];
    }
}