import { Card, Col, Tooltip, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

//TODO : HANDLE THE TITLES SIZE IN MOBILE


const ReleaseNotesList = (props:any) => {
    const { releases, id, version } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            const yOffset = -160; // Adjust this value to set the top padding
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y });
        }
    };

    //render element on select
    useEffect(() => {
        if (!id) {
            navigate(`/documentation/release-notes/${version}`);
        } else {
            scrollToElement(id);
        }
    }, [id, navigate, version]);

    //go to path element
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            scrollToElement(hash);
        }
    }, [location]);

    const handleCopyClick = (id: string) => {
        const element = `${window.location.origin}${window.location.pathname}#${id}`
        navigator.clipboard.writeText(element).then(() => {
            console.log('copied')
        });
    }

    return releases.map((release:any, index:number) =>
    {
        const id = release.title.toLowerCase().replace(/\s+/g, '').replace(/\//g, '-');
        console.log("id: ", id);
        return (
            <Col xs={24}>
            <Card
                // title={<Typography.Title copyable={{ text: `${window.location.origin}${window.location.pathname}#${id}`, icon: [<LinkOutlined key="copy-icon" style={{ color:'#000' }}/>, <CheckOutlined key="copied-icon" />] }} level={2} id={id} className="releaseTitle">{release.title}</Typography.Title>}
                // title={<Typography.Title level={2} style={{ marginLeft: '16px' }} copyable={{ icon: [<Tooltip title="Copy Link" ><FontAwesomeIcon icon={faLink} style={{ color: '#000' }} onClick={() => handleCopyClick(id)} /></Tooltip>, ''], tooltips: false }}>{release.title}</Typography.Title>}
                title={<>{release.title}<Typography.Text style={{ marginLeft: '16px' }} copyable={{ icon: [<Tooltip title="Copy Link" ><FontAwesomeIcon icon={faLink} style={{ color: '#000' }} onClick={() => handleCopyClick(id)} /></Tooltip>, ''], tooltips: false }}></Typography.Text></>}
                className="sectionCard releaseNotes"
                id={id}
            >
                {release.content.map((section:any, index:number) => (
                <div key={index}>
                    <div className="releaseNotesTitle">
                        <Typography.Title level={2} className="sectionTitle">
                            {section.icon}{' '}{section.subTitle}
                        </Typography.Title>
                    </div>
                    <div className="releaseNotesContent"><Markdown>{section.subContent}</Markdown></div>
                </div>
                ))}
            </Card>
            </Col>
        );
})
}

export default ReleaseNotesList