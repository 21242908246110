import { Card, Flex, Select } from 'antd';

import { useNavigate } from 'react-router-dom';

const ReleaseNotesSelector = (props: any) => {
    const navigate = useNavigate();
    const { options, defaultNotesValue } = props

    const handleChange = (value: string) => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        navigate(`/documentation/release-notes/${value}`);
    };

    return (
        <Card>
            <Flex vertical>
                
                <Select defaultValue={defaultNotesValue} options={options} onChange={handleChange} />
            </Flex>
        </Card>
    )
}

export default ReleaseNotesSelector