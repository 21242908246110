import '../../assets/scss/ModelValidation.scss';

import { Alert, Button, Card, Col, Divider, Drawer, Flex, Form, Popconfirm, Progress, Radio, Row, Select, Space, Tag, Typography, Upload, UploadFile, notification } from 'antd';
import { ArrowRightOutlined, CloseCircleOutlined, DownloadOutlined, FileOutlined, HomeOutlined, UploadOutlined } from '@ant-design/icons';
import { EnvContext, EnvUpdateContext } from '../../contexts/EnvironmentContext';
import { useCallback, useContext, useEffect, useState } from "react";

import BarChartSplittedCustom from './charts/BarChartSplittedCustom';
import CompareTable from './Tables/CompareTable';
import { Content } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MatchCompareTableV2 from './Tables/MatchCompareTableV2';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import RadarChartCompareCustom from './charts/RadarChartCompareCustom';
import RadarChartCustom from './charts/RadarChartCustom';
import ResultTableFlattened from './Tables/ResultTableFlattened';
import { SecureWithLogin } from "../../utils/secure";
import SetOptions from './creation/SetOptions';
import ShowOptions from './ShowOptions';
import Stats from './Stats';
import StatsTable from './Tables/StatsTable';
import { callBackEnd } from "../../utils/backEndUtils";
import { getCollectionFromRecord } from './utils/getCollectionFromRecord';
import { green } from '@ant-design/colors';
import { processJSONMvRecords } from './utils/processMvRecords';
import useAllEnvironments from '../../hooks/useAllEnvironments';
import useNamespaceById from '../../hooks/useNamespaceById';

export default function ModelValidation() {
    const { Title } = Typography;
    const env: string = useContext(EnvContext);
    const MAX_RUNS: number = 5;
    const [form] = Form.useForm();
    const { allEnv } = useAllEnvironments();
    const updateEnvironment = useContext(EnvUpdateContext);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    //Processing state
    const [processing, setProcessing] = useState<boolean>(false);

    //product
    const [selectedProduct, setSelectedProduct] = useState<string>('');

    //Collection
    const [selectedCollection, setSelectedCollection] = useState<string>("");

    //package
    const [selectedPackage, setSelectedPackage] = useState<any>(null);
    const [allPackages, setAllPackages] = useState<any>(null);
    const [packages, setPackages] = useState<any>(null);
    const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
    const { getNamespacePackages } = useNamespaceById();

    //datas
    const [records, setRecords] = useState<any[]>([]);
    const [context, setContext] = useState<any>({});
    const [options, setOptions] = useState({});

    //Results
    const [runsData, setRunsData] = useState<any[]>([]);
    const [detailedRunShowed, setDetailedRunShowed] = useState<number>(0); //-1 = run comparaison

    const [uploadErrors, setUploadErrors] = useState<string[]>([]);

    const [isRateLimited, setIsRateLimited] = useState<boolean>(false);

    const [newRunDrawerVisible, setNewRunDrawerVisible] = useState<boolean>(false);
    const [optionsDrawerVisible, setOptionsDrawerVisible] = useState<boolean>(false);

    useEffect(() => {
        if (runsData.length > 0) {
            if (runsData.length > 1) {
                setDetailedRunShowed(- 1);
            } else {

                setDetailedRunShowed(runsData.length - 1);
            }
        }
        setNewRunDrawerVisible(false)
    }, [runsData])

    //Get all the packages
    useEffect(() => {
        async function getPackages() {
            try {
                setLoadingPackages(true);
                const response: any = await getNamespacePackages({ withVolumes: true, limitUser: true }).catch((e: any) => console.log(e));

                setAllPackages(response);
            } catch (error: any) {
                notification.error({ message: 'An error occured while loading packages' });
            } finally {
                setLoadingPackages(false);
            }
        }
        getPackages().catch((e: any) => console.log(e));
    }, [getNamespacePackages]);

    useEffect(() => {

        if (allPackages && allPackages.length > 0 && selectedProduct !== '' && fileList.length > 0) {
            let filteredPackages: any[] = allPackages.filter((p: any) => p.type === 2); //By type
            //each package item should have at least a volume in (packageItem.volumes) with the product === selectedProduct
            filteredPackages = filteredPackages.filter((p: any) => p.volumes.filter((v: any) => v.product.toLowerCase() === selectedProduct.toLowerCase()).length > 0);
            //filter packages by environment
            filteredPackages = filteredPackages.filter((p: Package) => p.environment === env);

            if (filteredPackages.length === 1) {
                console.log(filteredPackages[0].uid);
                form.setFieldValue('package', filteredPackages[0].uid);
                setSelectedPackage(filteredPackages[0].uid);
            }
            setPackages(filteredPackages);
        }
        else {
            setPackages([]);
        }
    }, [allPackages, getNamespacePackages, selectedProduct, fileList, form, env]);

    useEffect(() => {
        if (records.length > 0 && packages?.length > 0 && selectedProduct && selectedPackage) {
            //find package, get collections by product and if 1 collection, return it
            const selectedPackageVolumes = packages.find((p: Package) => p.uid === selectedPackage)?.volumes;
            const volumesWithProduct = selectedPackageVolumes?.filter((v: any) => v.product.toLowerCase() === selectedProduct.toLowerCase());

            if (volumesWithProduct?.length === 1) {
                form.setFieldValue('collection', volumesWithProduct[0].collection);
                form.validateFields(['collection']);
                setSelectedCollection(volumesWithProduct[0].collection);
            } else {
                // else => get the suggested collection from the records
                const fromGuess = getCollectionFromRecord(records[0]);
                if (fromGuess) {
                    form.setFieldValue('collection', fromGuess.toUpperCase());
                    form.validateFields(['collection']);
                    setSelectedCollection(fromGuess);
                }
            }
        }

    }, [records, packages, selectedProduct, selectedPackage, form]);



    /**CALL API */
    const handleCallApi = async () => {
        if (selectedCollection !== '"default') {
            setProcessing(true);

            const makeMvApiCall = (recordsBatch: any[], screeningProduct: string) => {
                return new Promise((resolve, reject) => {
                    const data: any = {
                        records: recordsBatch,
                        options: options,
                        context: context
                    };

                    callBackEnd(`${env}`, 'POST', `api/v1/${screeningProduct}/screen/${selectedCollection.toLowerCase()}?packageId=${selectedPackage}`, JSON.stringify(data))
                        .then((response: any) => {
                            if (response.outcome === 'success') {
                                resolve(response)
                            }
                            else {
                                reject(response.errorMessage)
                            }
                        })
                        .catch((error: any) => {
                            reject(error)
                        })
                })
            }
            let screeningProduct: string = (records.filter((r: any) => typeof (r.object) === 'string').length > 0) ? 'jetscan' : 'jetflow';
            let batchesQuery: any[] = [];
            let allPromise: any[] = [];
            const recordsCopy: any = JSON.parse(JSON.stringify(records))
            while (recordsCopy.length) {
                const batch = recordsCopy.splice(0, 1000)
                batchesQuery.push(batch)
                allPromise.push(makeMvApiCall(batch, screeningProduct))
            }
            Promise.allSettled(allPromise)
                .then((responseBatches: any) => {
                    responseBatches.forEach((result: any, index: number) => {

                        if (result.status === 'fulfilled') {
                        } else if (result.status === 'rejected') {
                            if (result?.reason?.status === 429) {
                                setIsRateLimited(true);
                            }
                        }
                    });

                    const fulfilled: any[] = responseBatches.filter((p: any) => p.status === 'fulfilled');
                    if (fulfilled.length === allPromise.length) {
                        processResponseBatches(fulfilled.map((p: any) => p.value), batchesQuery, screeningProduct)
                    }
                    else {
                        processResponseBatches(fulfilled.map((p: any) => p.value), batchesQuery, screeningProduct)
                        notification.error({ message: 'Error while processing your request', duration: 0 });
                    }
                })
                .catch((error: any) => {
                    notification.error({ message: 'Error while processing your request', duration: 0 });
                    setProcessing(false);
                })

        }
    }

    const processResponseBatches = (responseBatches: any, batchesQuery: any, screeningProduct: string) => {
        const allMergedBatches = batchesQuery.map((batchQuery: any, batchIndex: number) => {

            // const allMergedBatches = batchesQuery.map((batchQuery: any, batchIndex: any) => {

            const batchMerged = batchQuery.map((query: any, index: number) => {
                let results = responseBatches[batchIndex].results[index];

                if (results) {
                    results.query = query;
                    //match ok = same profile ID and same location path
                    //For Jetscan, default location path => /
                    if (results.query.modelValidation.locationPath === undefined || results.query.modelValidation.locationPath === '') {
                        results.query.modelValidation.locationPath = '/'
                    }
                    if (results.matches.length > 0) {
                        if (results.matches.length > 0 && query.modelValidation?.profileId !== undefined) {
                            results.modelValidation.matchesOk = results.matches.filter((m: any) => m.profileId === query.modelValidation.profileId && m.location.path === query.modelValidation.locationPath)
                            results.modelValidation.matchesNok = results.matches.filter((m: any) => m.profileId !== query.modelValidation.profileId || m.location.path !== query.modelValidation.locationPath)
                        }
                        else {
                            results.modelValidation.matchesOk = []
                            results.modelValidation.matchesNok = []
                        }
                    }
                    else {
                        results.modelValidation.matchesOk = []
                        results.modelValidation.matchesNok = []
                    }

                    return results;
                } else {
                    return null;
                }
            })
            return batchMerged;
        })

        //count
        let totalCount = 0
        for (const batch of responseBatches) {
            totalCount += batch.count;
        }
        //Stats
        let groupedStats: {
            screenedRecords: number,
            hitRecords: number,
            hits: number,
            hitPercent: number | string,
            elapsed: any,
            screenedPerSecond: any,
            consumedQuota: any,
            screener: string,
        } = {
            screenedRecords: 0,
            hitRecords: 0,
            hits: 0,
            hitPercent: 0, //to convert to string with % sign (ex '43.3%')
            elapsed: 0, //to convert to string with [space]ms sign (ex '4206 ms')
            screenedPerSecond: 0, // to convert to string
            consumedQuota: 0, //to convert to string with % sign (ex '43.3%')
            screener: screeningProduct
        }
        for (let i = 0; i < responseBatches.length; i++) {
            /**
             * Carefull with HitPercent that will be calculated at the end based on records and total hits
             */
            groupedStats.screenedRecords += responseBatches[i].stats.screenedRecords
            groupedStats.hitRecords += responseBatches[i].stats.hitRecords
            groupedStats.hits += responseBatches[i].stats.hits
            groupedStats.elapsed += Number(responseBatches[i].stats.elapsed.split(' ')[0])
            groupedStats.screenedPerSecond += Number(responseBatches[i].stats.screenedPerSecond)
            groupedStats.consumedQuota += Number(responseBatches[i].stats.consumedQuota.split('%')[0])
        }

        groupedStats.hitPercent = String(groupedStats.hitPercent + "%");
        groupedStats.elapsed = String(groupedStats.elapsed + " ms");
        groupedStats.screenedPerSecond = String(groupedStats.screenedPerSecond);
        groupedStats.consumedQuota = String(Math.round(groupedStats.consumedQuota) + "%");

        setRunsData((currentRuns) => [...currentRuns, {
            outcome: responseBatches[0].outcome,
            count: totalCount,
            context: context,
            stats: groupedStats,
            options: options,
            mergedData: allMergedBatches.flat() //flattens the batches in one single array
        }]);
        setProcessing(false);
    }
    const handleChartData = useCallback((runIndex: number, chartData: any, type: string) => {
        if (selectedCollection !== '' && runsData.length > 0) {
            setRunsData((currentRunsData) => {
                let updatedRuns = [...currentRunsData];
                let runToUpdate = updatedRuns[runIndex];
                if (!runToUpdate.chartData) {
                    runToUpdate.chartData = {};
                }
                runToUpdate.chartData[type] = chartData;
                updatedRuns[runIndex] = runToUpdate;
                return updatedRuns;
            });
        }
    }, [selectedCollection, runsData.length]);

    const chartsData: any[] = runsData.filter((run) => {
        return run.chartData;
    }).map((run) => {
        return run.chartData;
    });

    //Export / Import

    const isExportable = (): boolean => {
        if (records && runsData) {
            if (selectedCollection !== 'default' && records.length > 0 && runsData.length > 0) {
                return true;
            }
            return false;
        }

        return false;
    }

    const handleImportBackup = async (e?: any) => {
        let reader = new FileReader();
        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onload = function (evt: any) {
            let importedDatas: any = null;
            try {
                importedDatas = JSON.parse(evt.target.result);
            }
            catch (e) {
                console.log(e);
            }
            if (importedDatas !== null) {
                if (importedDatas.selectedCollection !== undefined &&
                    importedDatas.records !== undefined &&
                    importedDatas.context !== undefined &&
                    importedDatas.runsData !== undefined
                ) {
                    setSelectedCollection(importedDatas.selectedCollection);
                    setRecords(importedDatas.records);
                    setContext(importedDatas.context);
                    setRunsData(importedDatas.runsData);

                    notification.success({ message: 'Successfully imported Model Validation runs' });
                }
            }
        }
        reader.onerror = function (evt) {
            notification.error({ message: 'Error while importing your Model Validation runs' });
        }

    }

    const exportModelValidationToJSON = async () => {
        const datas = {
            selectedCollection,
            records,
            ...(context !== null) && { context },
            ...(options !== null) && { options },
            runsData
        }
        const json = JSON.stringify(datas);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        let d: Date = new Date();
        let month: number = d.getMonth() + 1;
        let datestring: string = String(d.getFullYear()) + ((month > 9) ? month : '0' + month) + d.getDate() + "_" + d.getHours() + "-" + d.getMinutes();
        link.download = `neterium-export-modelValidation-${datestring}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleResetMv = () => {
        setRunsData([]);
        setSelectedCollection('');
        setRecords([]);
        setContext({});
        setOptions({});
        setSelectedPackage(null);
        setSelectedProduct('');
        setDetailedRunShowed(0);
        form.resetFields();
        setFileList([]);
    }

    return (
        <SecureWithLogin callbackPath="/model-validation" role={['portal_model_validation']} module="MODEL_VALIDATION" namespaceRequired={true}>
            <MotionBox>
                <Content>
                    <PageHeader
                        title='Model Validation'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>FAQ</span> }]}
                    // withEnvSelector
                    />
                    {/* Restore/backup and reset */}
                    <Card className="sectionCard modelValidation"
                        title={<Space size="middle">
                            Model Validation
                            <span>
                                <Progress
                                    steps={MAX_RUNS}
                                    percent={(runsData.length / MAX_RUNS) * 100}
                                    showInfo={false}
                                    strokeColor={green[6]}
                                />
                                {' '}
                                <Typography.Text>{MAX_RUNS - runsData.length} {(MAX_RUNS - runsData.length > 2 ? 'runs' : 'run')} left</Typography.Text>
                            </span>
                        </Space>}
                        extra={
                            <Flex gap="small" justify='vertical'>
                                {/* {((runsData.length === 0 && selectedCollection !== '') || selectedPackage !== null || selectedProduct !== '') && */}
                                <Button type="dashed" onClick={handleResetMv}
                                    icon={<CloseCircleOutlined />}
                                >
                                    Reset Model Validation
                                </Button>

                                {/* } */}
                                <div className="buttons">
                                    {runsData.length > 0 &&
                                        <Button type="primary" onClick={exportModelValidationToJSON} disabled={!isExportable()} icon={<DownloadOutlined />}>
                                            Save current session
                                        </Button>
                                    }
                                    {runsData.length === 0 &&
                                        <Button type="primary" icon={<DownloadOutlined />} >
                                            <label className="">
                                                <span>Restore saved session from file</span>
                                                <input type='file' onChange={handleImportBackup} className="hidden" accept=".json" />
                                            </label>
                                        </Button>
                                    }
                                </div>
                            </Flex>
                        }>
                        {(!processing && runsData.length === 0) &&
                            <Card title="New Model Validation Session" type="inner">
                                <Form
                                    form={form}
                                    labelWrap
                                    // onFinish={handleForm}
                                    onValuesChange={(values) => {
                                        if (values?.collection) {
                                            setIsRateLimited(false); //resets the rate limit display
                                        }
                                        form.validateFields(['collection'])
                                    }}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                >
                                    <Form.Item
                                        label="Environment"
                                        name="environment"
                                        tooltip="The environment you want to use for the model validation"
                                        rules={[{ required: true, message: 'Please select an environment' }]}
                                        initialValue={env}
                                    >

                                        <Radio.Group
                                            buttonStyle="solid"
                                            onChange={(e: any) => {
                                                if (e.target.value !== undefined && updateEnvironment) {
                                                    form.resetFields(['collection', 'product']);
                                                    updateEnvironment(e.target.value);
                                                }
                                            }}
                                        >
                                            {allEnv.map((env: any, index: number) => {
                                                if (env.name !== 'PROD') {
                                                    return (
                                                        <Radio.Button key={env.name} value={env.name}>{env.label}</Radio.Button>
                                                    )
                                                }
                                                return null;
                                            })}
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload records"
                                        rules={[{ required: true, message: 'Please upload records' }]}
                                        shouldUpdate={true}
                                        tooltip={<>Upload a JSON file containing your records, which will be used exclusively for this Model Validation session. Refer to the <Link to="/doc">API Documentation</Link> to ensure the JSON format is valid for the selected engine.</>}
                                        help={<p style={{ fontSize: "12px", margin: "10px 0" }}><ArrowRightOutlined /> Download the Neterium <a href="/modelValidationTemplate.json" download><FileOutlined /> Model Validation template</a></p>}
                                    >
                                        <Upload
                                            fileList={fileList}
                                            maxCount={1}
                                            accept=".json"
                                            // showUploadList={false}
                                            onRemove={() => {
                                                setFileList([]);
                                                setRecords([]);
                                            }}
                                            beforeUpload={(file: UploadFile) => {
                                                const isJson: boolean = file.type === 'application/json';
                                                if (!isJson) {
                                                    notification.error({ message: 'Only JSON files are allowed' });
                                                }
                                                else {
                                                    setFileList([file]);

                                                    const getProcessedRecords = async () => {
                                                        try {
                                                            const processed: any = await processJSONMvRecords(file).catch((e) => console.log(e));
                                                            if (processed.success) {
                                                                if (processed.data.options !== null) setOptions(processed.data.options);
                                                                if (processed.data.context !== null) setContext(processed.data.context);
                                                                if (processed.data.records.length > 0) setRecords(processed.data.records);
                                                                setUploadErrors(processed.errorMessages);
                                                                if (processed.product !== '') {
                                                                    form.resetFields(['collection']);
                                                                    setSelectedProduct(processed.product.toLowerCase());
                                                                }
                                                            }
                                                            else {
                                                                notification.error({ message: 'Error while processing your file', duration: 0 });
                                                            }
                                                        }
                                                        catch (e) {
                                                            console.log(e)
                                                        }
                                                    }
                                                    getProcessedRecords();
                                                }
                                                return false;
                                            }
                                            }
                                        >
                                            <Button disabled={fileList.length > 0} hidden={fileList.length > 0} icon={<UploadOutlined />}>Click to upload</Button>
                                        </Upload>
                                        {records.length > 0 && <p style={{ marginTop: "10px" }}><Tag color="green">{records.length} valid {selectedProduct.toUpperCase()} records</Tag></p>}

                                        {setUploadErrors.length > 0 && <Space direction="vertical" style={{ marginTop: "10px" }} size={'small'}>
                                            {uploadErrors?.map((error: string, index: number) => {
                                                return <Tag color="red" key={index+crypto.randomUUID()}>{error}</Tag>
                                            })}
                                        </Space>}
                                    </Form.Item>
                                    <Row>
                                        <Col offset={3}>
                                            {(records.length > 0 && packages && packages.length === 0) && <Alert style={{marginBottom:"20px"}} message={`${selectedProduct.toUpperCase()} package not available for Model Validation.`} type="warning" showIcon closable />}
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        label="Package"
                                        name="package"
                                        tooltip="Select a Dashboard package for Model Validation"
                                        rules={[{ required: true, message: 'Please select a package' }]}
                                    >
                                        <Select
                                            placeholder="Select a package"
                                            onChange={(value: any) => {
                                                setSelectedPackage(value);
                                            }}
                                            value={selectedPackage}
                                            loading={loadingPackages}
                                            style={{ width: '100%', maxWidth: '300px' }}
                                            disabled={selectedProduct === '' || fileList.length === 0 || packages?.length === 0}
                                        >
                                            {packages && packages.length > 0 && packages.map((packageItem: any) => {
                                                return (
                                                    <Select.Option key={packageItem.uid} value={packageItem.uid}>{packageItem.name}</Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Collection"
                                        name="collection"
                                        tooltip="Select the collection for model validation from the available collections based on the chosen environment and engine."
                                        rules={[{ required: true, message: 'Please select a collection' }]}
                                    >
                                        <Select
                                            placeholder="Select a collection"
                                            onChange={(value: string) => setSelectedCollection(value)}
                                            value={selectedCollection}
                                            disabled={selectedProduct === '' || selectedPackage === null}
                                            style={{ width: '100%', maxWidth: '300px' }}>
                                            {
                                                selectedPackage && packages?.find((p: Package) => selectedPackage === p.uid)?.volumes?.map((volume: any) => {
                                                    if (volume?.product?.toLowerCase() === selectedProduct.toLowerCase()) {
                                                        return <Select.Option key={volume.uid} value={volume.collection}>{volume.collection}</Select.Option>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Options"
                                        name="options"
                                        tooltip="Configure the options you want to apply to the records for this model validation run."
                                    >
                                        {records.length > 0 && selectedCollection && selectedPackage !== null ? <SetOptions options={options} setOptions={setOptions} /> : <Typography.Text type="secondary">Please upload records, select a package and a collection</Typography.Text>}
                                    </Form.Item>
                                    {(!processing && runsData.length === 0) &&
                                        <>
                                            {selectedCollection !== '' && records.length > 0 && selectedPackage && selectedProduct &&
                                                <Flex justify='flex-end'>
                                                    <Space size="middle">
                                                        {isRateLimited &&
                                                            <Space size="middle">
                                                                <Tag color="error">You have reached the screening limit for this collection.</Tag>
                                                            </Space>
                                                        }
                                                        <Button type="primary" onClick={handleCallApi} loading={processing} size="large" disabled={isRateLimited}>
                                                            Execute
                                                        </Button>
                                                    </Space>
                                                </Flex>
                                            }
                                        </>
                                    }
                                </Form>
                            </Card>
                        }

                        {(processing) &&
                            <LoadingSkeleton />
                        }

                        {(runsData.length > 0 && runsData.length < MAX_RUNS) &&
                            <Drawer
                                open={newRunDrawerVisible}
                                placement='right'
                                onClose={() => setNewRunDrawerVisible(false)}
                                size='large'
                                zIndex={1010}
                                title="Add another run"
                                loading={processing}
                                extra={
                                    <Typography.Text type="secondary">{MAX_RUNS - runsData.length} {(MAX_RUNS - runsData.length > 2 ? 'runs' : 'run')} left</Typography.Text>
                                }>
                                <Flex gap="large" justify="center" vertical>
                                    <SetOptions options={options} setOptions={setOptions} />
                                    {/* <div className="actions"> */}
                                    <Flex gap="middle" justify='right'>
                                        <Button type="default" onClick={() => setNewRunDrawerVisible(false)}>Cancel</Button>
                                        <Button type="primary" onClick={handleCallApi} loading={processing}>
                                            Execute
                                        </Button>
                                    </Flex>
                                </Flex>
                                {/* </div> */}

                            </Drawer>

                        }

                        {runsData.length > 0 &&
                            <>
                                <Flex gap="small" wrap="wrap" style={{ marginBottom: '20px' }}>
                                    {runsData.length > 1 &&
                                        <>
                                            <Button type={detailedRunShowed === -1 ? "primary" : "default"} onClick={() => setDetailedRunShowed(-1)} value={-1} >Run Comparison</Button>
                                        </>
                                    }
                                    {
                                        runsData.map((data, index) => (
                                            <Button key={index+"-"+crypto.randomUUID()} type={detailedRunShowed === index ? "primary" : "default"} onClick={() => setDetailedRunShowed(index)} value={index}>View Run {index + 1}</Button>
                                        ))
                                    }
                                    {runsData.length > 0 && runsData.length < MAX_RUNS &&
                                        <Button type="dashed" onClick={() => setNewRunDrawerVisible(true)} disabled={runsData.length === MAX_RUNS}>Add another run</Button>
                                    }

                                </Flex>
                            </>
                        }

                        {runsData.length >= 2 && detailedRunShowed === -1 &&
                            <>
                                <MotionBox>
                                    <Card
                                        title="Run Comparison"
                                        type="inner"
                                        bordered={false}

                                    >
                                        <RadarChartCompareCustom chartData={chartsData} />
                                        <Divider />
                                        <CompareTable runsData={runsData} />
                                        <MatchCompareTableV2 runsData={runsData} />
                                    </Card>
                                </MotionBox>
                            </>
                        }
                        {
                            (!processing && runsData.length > 0) &&
                            <>
                                {runsData.map((data: any, index: number) => {
                                    return (
                                        <div key={index+crypto.randomUUID()} className="runData">
                                            <MotionBox>
                                                <div className={`${(detailedRunShowed === index) ? 'visible' : 'hidden'}`}>
                                                    <Card
                                                        title={<Space size={'middle'}>
                                                            <span>Run {index + 1}</span>
                                                            {runsData.length > 1 &&
                                                                <Popconfirm
                                                                    title="Are you sure to remove this run?"
                                                                    onConfirm={() => {
                                                                        let runs = [...runsData];
                                                                        runs.splice(index, 1);
                                                                        setRunsData(runs);
                                                                        setDetailedRunShowed(0);
                                                                    }}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="link" danger size='small'>
                                                                        <CloseCircleOutlined /> Remove this run
                                                                    </Button>
                                                                </Popconfirm>
                                                            }
                                                        </Space>}
                                                        bordered={false}
                                                        type="inner"
                                                        extra={<Button type="default" onClick={() => setOptionsDrawerVisible(true)}>Options applied</Button>}
                                                    >
                                                        {(data.stats) &&
                                                            <Stats stats={data.stats} collection={selectedCollection} />
                                                        }
                                                        <Divider />
                                                        <Drawer
                                                            open={optionsDrawerVisible}
                                                            placement='right'
                                                            onClose={() => setOptionsDrawerVisible(false)}
                                                            size='large'
                                                            zIndex={1010}
                                                            title="Options"
                                                            loading={processing}
                                                        >

                                                            <ShowOptions options={data?.options} defaultOpen={true} />
                                                        </Drawer>

                                                        <Row gutter={[16, 16]}>
                                                            <Col span={24} md={12}>
                                                                <Title level={5}>
                                                                    Effectiveness
                                                                </Title>
                                                                <Typography.Text>
                                                                    Number of expected matches
                                                                </Typography.Text>
                                                                <div className="chartContainer">
                                                                    {data.mergedData.length > 0 &&
                                                                        <RadarChartCustom mergedData={data?.mergedData} runIndex={index} handleChartData={handleChartData} />
                                                                    }
                                                                </div>
                                                                <StatsTable currentTabDetailedCharts="effectiveness" mergedData={data.mergedData} options={(data.options !== undefined) ? data.options : null} runIndex={index} />
                                                            </Col>
                                                            <Col span={24} md={12}>
                                                                <Title level={5}>
                                                                    Efficiency
                                                                </Title>
                                                                <Typography.Text>
                                                                    Number of extra matches
                                                                </Typography.Text>
                                                                <div className="chartContainer">
                                                                    {data.mergedData &&
                                                                        <BarChartSplittedCustom mergedData={data?.mergedData} runIndex={index} handleChartData={handleChartData} />
                                                                    }
                                                                </div>
                                                                <StatsTable currentTabDetailedCharts="efficiency" mergedData={data.mergedData} options={(data.options !== undefined) ? data.options : null} runIndex={index} />
                                                            </Col>
                                                        </Row>
                                                        <Divider />
                                                        <ResultTableFlattened mergedData={data?.mergedData} options={(data.options !== undefined) ? data.options : null} context={(data.context !== undefined) ? data.context : null} />
                                                    </Card>
                                                </div>
                                            </MotionBox>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </Card>
                </Content>
            </MotionBox>
        </SecureWithLogin >
    )
}
