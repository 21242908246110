import './../../assets/scss/HorizontalMenu.scss';

import { Menu } from 'antd';
import { useFeatures } from '../../hooks/useFeatures';
import { useLocation } from 'react-router-dom';

export default function HorizontalMenu() {

    const location = useLocation();
    const pathname = location.pathname;

    const features = useFeatures();

    const getFeatureKeyFromPathname = (pathname: string): string[] => {

        const feature = features.find((f: Feature) => f.link === pathname);
        if (!feature) {
            for (const f of features) {
                if (f.children) {
                    const childFeature = f.children.find((child: Feature) => child.link === pathname);
                    if (childFeature) {
                        return [childFeature.key];
                    }
                }
            }
        }
        //Temp fix for the release notes
        if(pathname.includes('release-notes')) {
            return ['release-notes'];
        }

        return feature ? [feature.key] : [];
    }


    return (
        <Menu
            className='horizontalMenu'
            mode="horizontal"
            theme="dark"
            selectable={false}
            items={features as any}
            triggerSubMenuAction="click"
            defaultSelectedKeys={[pathname]}
            selectedKeys={getFeatureKeyFromPathname(pathname)}
        />
    )
}
