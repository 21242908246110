import { Card, Col, Descriptions, Divider, Row, Timeline, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import { ClockCircleOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout"
import Moment from "react-moment";
import MotionBox from "../Layout/Animated/MotionBox";
import { SecureWithLogin } from "../../utils/secure";

export default function SupportTicket() {
    const { ticketId } = useParams();

    const ticketData: any = {
        key: '1',
        case: 'Issue with the login page',
        tags: ['question'],
        status: 'open',
        reporter: 'John Doe',
        lastUpdated: new Date(),
        creationDate: new Date()
    }

    return (
        <SecureWithLogin module='support' callbackPath="/support" role={[]} namespaceRequired={true}>
            <MotionBox>
                <Content>
                    <Row gutter={[15, 15]}>
                        <Col md={14} sm={24}>
                            <Card
                                title={`Support Ticket ${ticketId}`}
                                key={ticketId}
                                type="inner"
                                extra={<Link to="/support">Back to Support homepage</Link>}
                            >
                                <Descriptions>
                                    <Descriptions.Item label="Tags">{ticketData.tags.toString()}</Descriptions.Item>
                                    <Descriptions.Item label="Status">{ticketData.status}</Descriptions.Item>
                                    <Descriptions.Item label="Reporter">{ticketData.reporter}</Descriptions.Item>
                                    <Descriptions.Item label="Last Updated"><Moment format="YYYY/MM/DD h:mm:ss a">{ticketData.lastUpdated}</Moment></Descriptions.Item>
                                    <Descriptions.Item label="Creation Date"><Moment format="YYYY/MM/DD h:mm:ss a">{ticketData.creationDate}</Moment></Descriptions.Item>
                                </Descriptions>
                                <Divider />
                                <Typography.Text>
                                    <Moment format="YYYY/MM/DD h:mm:ss a">{ticketData.creationDate}</Moment>
                                    {ticketData.case}
                                </Typography.Text>
                            </Card>
                        </Col>
                        <Col md={10} sm={24}>
                            <Card title="Timeline" type="inner">
                                <Timeline
                                    items={[
                                        {
                                            color: 'green',
                                            children: <>Issue reported by {ticketData.reporter} on {<Moment format="YYYY/MM/DD h:mm:ss a">{ticketData.creationDate}</Moment>}</>,
                                        },
                                        {
                                            color: 'green',
                                            children: <>Support responding on <Moment format="YYYY/MM/DD h:mm:ss a">{ticketData.creationDate}</Moment></>
                                        },
                                        {
                                            dot: <ClockCircleOutlined className="timeline-clock-icon" />,
                                            color: 'red',
                                            children: 'Technical testing 2015-09-01',
                                        },
                                        {
                                            children: 'Network problems being solved 2015-09-01',
                                        },
                                    ]}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
