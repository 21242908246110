import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { useRoles } from "../contexts/RolesContext";

export default function useUserGuide(type: string) {
    const [userGuide, setUserGuide] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const rolesCtx = useRoles();

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                const content: any = await callBackEnd('portal', 'GET', `content/userGuide`);
                
                setUserGuide(content.filter((item: any) => item?.key?.toLowerCase() === type));
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        getData().catch((e: any) => { console.log(e) });

    }, [rolesCtx, type]);

    return { userGuide, loading, error };
}
