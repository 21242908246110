import '../../../assets/scss/commonModules/Modal.scss';

import { Flex, Input, Modal } from 'antd';

import { useState } from 'react';

interface IModalProps {
    title: string
    message: string
    callback: any
    secureString?: string
}

export default function CustomModal(props: Readonly<IModalProps>) {
    const { title, message, callback, secureString } = props;

    const [secureConfirmation, setSecureConfirmation] = useState<string>('');

    const handleShowConfirm = () => {

        if (secureString === undefined) return false;

        if (secureConfirmation === secureString) return false;

        return true;
    }

    return (
        <Modal centered title={title} open={true} onOk={(e) => callback(true)} onCancel={(e) => callback(false)}  okButtonProps={{ disabled: handleShowConfirm() }}>
            <Flex vertical gap={"middle"}>
                <p>{message}</p>
                {secureString !== undefined &&
                    <Input value={secureConfirmation} onChange={(e: any) => setSecureConfirmation(e.target.value)} placeholder={secureString} />
                }
            </Flex>
        </Modal>

    )
}
