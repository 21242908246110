import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

export default function StatusPills(props: { type: string | boolean }) {
    const { type } = props;

    const isEnabled = (typeof type === 'boolean' && type) || (typeof type === 'string' && type.toLowerCase() === 'enabled');
    const isDisabled = (typeof type === 'boolean' && !type) || (typeof type === 'string' && type.toLowerCase() === 'disabled');

    return (
        <>
            {isEnabled && <CheckCircleTwoTone twoToneColor="#52c41a" />}
            {isDisabled && <CloseCircleTwoTone twoToneColor="#eb2f96" />}
        </>
    )
}