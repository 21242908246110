import './../../assets/scss/OidcComponent.scss';

import { Button, Result } from 'antd'

export default function SessionLost() {
    return (
        <div className="oidcComponent">
            <Result
                title="Your session has expired due to inactivity"
                subTitle="Please log in again to continue"
                extra={

                    <Button type="primary" onClick={() => window.location.reload()}>
                        Back to Login
                    </Button>

                }
            />
        </div>
    )
}
