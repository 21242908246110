import { Bar, BarChart, Brush, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import { Typography } from "antd";

export default function CustomScoreChart(props: { stats: Promise<any>, product: string, title: string }) {
    const { stats, product, title } = props;
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await stats;
                setData(result);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [stats]);

    return (
        <>
            {
                ((data.length > 0 && !loading) ?
                    <>
                        <Typography.Title level={5}>
                            {title}
                        </Typography.Title>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 10
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={"score"} />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                {data.length > 1 &&
                                    <Brush dataKey="name" height={20} stroke="#82b9d6" />
                                }

                                <Bar type="monotone" dataKey="count" fill={product.toLowerCase() === 'jetscan' ? "#38bdf8" : '#f59e0b'} />

                            </BarChart>
                        </ResponsiveContainer>
                    </>
                    : <LoadingSkeleton />)
            }
        </>
    )
}
