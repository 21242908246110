import { Button, Card, Input, Space, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { EyeTwoTone, SearchOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox';
import StatusPills from '../common/StatusPills';
import { useState } from 'react';

export default function UsersListWithNamespace(props: Readonly<IUsersListWithNamespaceProps>) {
    const { users, title, namespaces } = props;

    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();

    const getNamespaceName = (uid: string | undefined) => {
        if (uid === undefined) return '';
        const namespace = namespaces.find((n: Namespace) => n.uid === uid);
        return namespace?.label;
    }

    const showVerifiedStatus = (value: string) => {
        switch (value) {
            case 'REGISTERED':
            case 'NAMESPACE_JOIN_REQUESTED':
                return <Tag color="blue">{value}</Tag>
            case 'VERIFIED':
                return <Tag color="green">{value}</Tag>
            case 'REJECTED':
                return <Tag color="red">{value}</Tag>
        }
    }
    const getDistinctCompanyNames = () => {
        const companyNames: string[] = [];
        users.forEach((u: UserProfile) => {
            if (u.company && !companyNames.includes(u.company)) companyNames.push(u.company);
        });
        return companyNames;
    }
    const getDistinctNamespaces = () => {
        const namespaceNames: string[] = [];
        users.forEach((u: UserProfile) => {
            if (u.namespaceId && !namespaceNames.includes(u.namespaceId)) namespaceNames.push(u.namespaceId);
        });
        return namespaceNames;
    }
    const columns: TableColumnsType<UserProfile> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a: UserProfile, b: UserProfile) => a.firstName.localeCompare(b.firstName),
            onFilter: (value: any, u: any) => {
                return u.firstName.toLowerCase().includes(value.toLowerCase()) ||
                    u.lastName.toLowerCase().includes(value.toLowerCase()) ||
                    u.email.toLowerCase().includes(value.toLowerCase());
            }
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a: UserProfile, b: UserProfile) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: UserProfile, b: UserProfile) => a.email.localeCompare(b.email),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            filters: getDistinctCompanyNames().map((c: string) => ({ text: c, value: c })),
            onFilter: (value: any, u: any) => u.company === value,
        },
        {
            title: 'Namespace',
            dataIndex: 'namespaceId',
            key: 'namespaceId',
            render: (v: any, u: any) => u?.namespaceId ? getNamespaceName(u?.namespaceId) : 'N/A',
            hidden: (namespaces.length === 0),
            filters: [
                ...getDistinctNamespaces().map((n: string) => ({ text: getNamespaceName(n), value: n })),
                { text: 'Not in a Namespace', value: 'no_namespace' }, // Add this line
            ],
            onFilter: (value: any, record: any) => {
                if (value === 'no_namespace') {
                    return !record.namespaceId;
                }
                return record.namespaceId === value;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Namespace Pending', value: "REGISTERED" },
                { text: 'Join Requested', value: "NAMESPACE_JOIN_REQUESTED" },
                { text: 'Verified', value: "VERIFIED" },
                { text: 'Rejected', value: "REJECTED" },
            ],
            onFilter: (value: any, u: any) => u.status === value,
            render: (value: string) => <>{showVerifiedStatus(value)}</>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (value: boolean) => <StatusPills type={value} />,
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value: any, u: any) => u.enabled === value,
            sorter: (a: UserProfile, b: UserProfile) => a.enabled.toString().localeCompare(b.enabled.toString()),
        },

        {
            title: 'Last Login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            render: (value: Date) => <Moment date={value} format="YYYY-MM-DD hh:mm:ss" />
        },

    ];
    columns.push({
        title: 'Actions',
        dataIndex: 'action',
        key: 'x',
        render: (v: any, u: any) => <Tooltip title="View user details" placement="top"><Button type="link" onClick={() => navigate(`/users/${u.uid}`)}><EyeTwoTone /></Button></Tooltip>,
    });

    return (
        <MotionBox>

            <Card
                title={<><UsergroupAddOutlined />{' '}{title}</>}
                className="userList"
                type="inner"
                extra={
                    <Space size="small">
                        <Input
                            type="text"
                            placeholder="Search First Name, Last Name or E-Mail"
                            name="search"
                            className="search"
                            onChange={(e: any) => setSearch(e.target.value)}
                            prefix={<SearchOutlined />}
                            style={{ width: '300px' }}
                        />
                        <Link to="/users/create">
                            <Button type="primary" icon={<UserAddOutlined />}>
                                Add new user
                            </Button>
                        </Link>
                    </Space>
                }
            >
                {users.length > 0 &&
                    <Table
                        dataSource={users.filter((u: UserProfile) => {
                            return u.firstName.toLowerCase().includes(search.toLowerCase()) ||
                                u.lastName.toLowerCase().includes(search.toLowerCase()) ||
                                u.email.toLowerCase().includes(search.toLowerCase());

                        })}
                        columns={columns}
                        rowKey="uid"
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            showSizeChanger: users.length > 10,
                            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
                            defaultPageSize: 50
                        }}
                    />
                }
            </Card>

        </MotionBox>
    )
}
