export const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
        }
    );
}
export const removeMarkdownFromString = (content: string): string => {
    content = content.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
    return content.replace(/[#*`_]/g, '');
}

export const capitalizeFirstLetter = (content:string) => {
    return content.charAt(0).toUpperCase() + content.slice(1);
}