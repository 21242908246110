import './../../assets/scss/OidcComponent.scss';

import { Button, Result } from 'antd'

import { Link } from 'react-router-dom'

export default function AuthenticationError() {
    return (
        <div className="oidcComponent">
            <Result
                status="error"
                title="Authentication failed"
                subTitle="Please try again"
                extra={
                    <Link to="/login">
                        <Button type="primary">
                            Try again
                        </Button>
                    </Link>
                }
            />
        </div>
    )
}
