import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils"

export default function useClusterStatus(env: string) {
    const [clusterStatus, setClusterStatus] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const targetEnv: string = `${env}`;

    useEffect(() => {
        async function getClusterStatus() {
            try {
                setLoading(true);
                setError(null);

                await callBackEnd(targetEnv, 'GET', 'api/v1/core/cluster-status')
                    .catch((e: any) => {
                        setError(e);
                    })
                    .then((result: any) => {
                        setClusterStatus(result);
                    })

            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getClusterStatus();

    }, [targetEnv]);

    return { clusterStatus, loading, error };
}