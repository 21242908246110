import '../../assets/scss/ReleaseNotes.scss';

import { Col, Row } from 'antd';
import { FormatPainterOutlined, HomeOutlined, NotificationOutlined, TagsOutlined, ToolOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from "../Layout/LoadingSkeleton";
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import ReleaseNotesList from './releaseNotesComponents/ReleaseNotesList';
import ReleaseNotesSelector from './releaseNotesComponents/ReleaseNotesSelector';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import useReleaseNotes from "../../hooks/useReleaseNotes";

//title icons
const subtitleIcons = [
    { icon: <NotificationOutlined />, title: '## New Features\r' },
    { icon: <ToolOutlined />, title: '## Fixes\r' },
    { icon: <FormatPainterOutlined />, title: '## Others improvements\r' },
    { icon: <TagsOutlined />, title: '## Related Service versions\r' }
];

const ReleaseNotes = () => {
    const { releaseNotes, versions, loading, error } = useReleaseNotes();
    const [releases, setReleases] = useState<any[]>([]);
    const [selectedRelease, setSelectedRelease] = useState<any>();

    const { id } = useParams();
    const navigate = useNavigate();
    const options = versions.map((version) => ({
        key: version,
        label: "Release " + version,
        value: version
    }));
    //redirect to the first release notes if the id doesn't match any release notes
    useEffect(() => {
        if (id && releaseNotes && releaseNotes.length > 0) {
            const selected = releaseNotes?.find(release => release.version === id);
            (!selected) ? navigate(`/documentation/release-notes/${releaseNotes[0].version}`) : setSelectedRelease(selected);
        }
    }, [id, releaseNotes, navigate]);

    //set the selected release notes and split the content into sections
    useEffect(() => {
        (id && releaseNotes) ? setSelectedRelease(releaseNotes.find(release => release.version === id)) : setSelectedRelease(releaseNotes[0]);
        const sections = selectedRelease?.content.split(/(?=^# )/gm).filter((section: any) => section.trim() !== '').map((section: string) => {
            const [title, ...data] = section.split('\n');
            const content = data.join('\n').split(/(?=^## )/gm).filter((section: any) => section.trim() !== '').map((section: string) => {
                const [subTitle, ...subContent] = section.split('\n');
                const icon = subtitleIcons.find(item => item.title === subTitle)?.icon;
                return { subTitle: subTitle.trim().replace(/^## /, ''), subContent: subContent.join('\n'), icon: icon };
            });

            return { title: title.trim().replace(/^# /, ''), content };
        });

        // sections && console.log(sections)
        releaseNotes && setReleases(sections);
    }, [releaseNotes, id, selectedRelease]);
    return (
        <SecureWithLogin callbackPath="/releaseNotes" module='RELEASE_NOTES' role={[]} namespaceRequired={true}>
            {(!loading && error === null && releaseNotes !== null) ?
                <MotionBox>
                    <PageHeader
                        title='Release Notes'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Release notes</span> }]}
                    />
                    <Content>
                        <Row gutter={[24, 24]} className='releaseNotesLayout'>
                            <Col xs={24}>
                                {releaseNotes && releases && releases.length > 0 && selectedRelease && options && <ReleaseNotesSelector options={options} defaultNotesValue={selectedRelease.version} />}
                            </Col>
                            <Col xs={24}>
                                    <Row gutter={[24,24]}>
                                    {releaseNotes && releases && releases.length > 0 && selectedRelease && <ReleaseNotesList id={id} releases={releases} version={selectedRelease.version} />}
                                    </Row>
                            </Col>
                            
                        </Row>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                </>
            }
        </SecureWithLogin >
    )
}
export default ReleaseNotes