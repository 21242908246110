import { Button, Card, Col, Row, Space, Typography } from 'antd';

import { Flex } from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import React from 'react';
import { removeMarkdownFromString } from '../../utils/stringUtils';

const { Title } = Typography;

interface NewsCardProps {
    news: {
        id: string;
        environment: string[];
        dateStart: string;
        title: string;
        image: string;
        content: string;
    };
    showEnvPill: (env: string) => JSX.Element | null;
    key: string;
}



const NewsCard: React.FC<NewsCardProps> = ({ news: n, showEnvPill }) => {
    return (
        <Card
            className='NewsCard'
            key={n.id}
            title={n?.environment?.map((env: string) => { return <span key={env}>{showEnvPill(env)}</span>; })}
            extra={
                <Space>
                    <Moment format="YYYY/MM/DD h:mm:ss a">
                        {n.dateStart}
                    </Moment>
                </Space>
            }
        >
            <div className="newsContent" key={n.id}>
                <Row>
                    <Col xs={24}>
                        <Flex gap="small">
                            <Space>
                                <div className="newsArticle">
                                    <div className="newsTitle">
                                        <Title level={4}>{n.title}</Title>
                                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }}>
                                            {removeMarkdownFromString(n.content)}
                                        </Typography.Paragraph>
                                    </div>
                                </div>
                            </Space>
                        </Flex>
                    </Col>
                    <Col xs={24}>
                        <Flex vertical align='end' ><Link to={`/news/${n.id}`}><Button type='primary'>Read More</Button></Link></Flex>
                    </Col>
                </Row>
            </div>
        </Card>
    );
}

export default NewsCard;