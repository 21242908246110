import { useEffect, useState } from 'react';

export default function useAllProducts() {
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        const products: Product[] = [
            {
                label: "Jetscan",
                value: "JETSCAN",
                description: "Highly efficient counterparty screening API",
                color: "#38bdf8"
            },
            {
                label: "Jetflow",
                value: "JETFLOW",
                description: "infinitely scalable transaction screening API",
                color: "#f59e0b"
            }
        ]
        setProducts(products);
    }, [])

    return { products };
}
