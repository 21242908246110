import { SecureWithLogin, useOidcAccessToken } from '../../utils/secure';
import { useCallback, useContext } from 'react';

import { Content } from 'antd/es/layout/layout';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader';
import { RedocStandalone } from 'redoc';
import ShowError from '../Layout/ShowError';
import { getBackEndURL } from '../../utils/backEndUtils';
import useClusterStatus from '../../hooks/useClusterStatus';

export default function Doc() {
    const env: string = useContext(EnvContext);
    const { clusterStatus, loading, error } = useClusterStatus(env);
    const documentationVersion: string = clusterStatus?.apiVersion || "1.7.0";
    const { accessToken } = useOidcAccessToken();


    const renderDocumentation = useCallback((apiDef: string) => {

        const apiUrl: string = `${getBackEndURL('portal')}documentation/api?version=${apiDef}`
        return (

            <RedocStandalone
                specUrl={apiUrl}
                options={{
                    nativeScrollbars: true,
                    scrollYOffset: 75,
                    theme: {
                        colors: {
                            primary: {
                                main: '#82b9d6',
                                light: '#82b9d6',
                            }
                        }
                    },
                    hideDownloadButton: true,

                }}
            />
        )
    }, []);
    return (
        <SecureWithLogin callbackPath="/doc" module='API_DOCUMENTATION' role={[]} namespaceRequired={true}>
            {(!loading && error === null && accessToken) ?
                <MotionBox>
                    <Content className="documentation sectionCard">
                        <PageHeader
                            title='API Documentation'
                            subtitle='Portal'
                            breadcrumbs={[
                                { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                                { title: <span>Docs</span> }]}
                        />
                        <div style={{ backgroundColor: '#fff', padding: '24px',borderRadius:"6px" }}>
                            {renderDocumentation(documentationVersion)}
                        </div>

                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={`Unable to get the status of the ${env.toLowerCase()} environment`} />}
                </>

            }
        </SecureWithLogin>
    )
}
