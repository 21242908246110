import { Content } from 'antd/es/layout/layout'
import { HomeOutlined } from '@ant-design/icons'
import LoadingSkeleton from '../Layout/LoadingSkeleton'
import Markdown from 'react-markdown'
import MotionBox from '../Layout/Animated/MotionBox'
import { PageHeader } from '../Layout/header/PageHeader'
import { SecureWithLogin } from '../../utils/secure'
import ShowError from '../Layout/ShowError'
import { Typography } from 'antd'
import { useParams } from 'react-router-dom'
import useUserGuide from '../../hooks/useUserGuide'
export default function UserGuide() {
    const { type } = useParams();
    const { userGuide, loading, error } = useUserGuide(type as string);

    return (
        <SecureWithLogin callbackPath="/user-guide" role={[]} namespaceRequired={true}>
            <MotionBox>
                <Content className='faq'>
                    <PageHeader
                        title='User Guide'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>User Guide</span> }]}
                    />
                    {(!loading && error === null && userGuide !== null && userGuide.length > 0) ?
                        <>
                            <Typography.Title level={2}>{userGuide[0]?.title}</Typography.Title>
                            <Markdown children={userGuide[0]?.content} />
                        </>
                        :
                        <>
                            {loading && <LoadingSkeleton />}
                            {error && <ShowError error={error.toString()} />}
                        </>
                    }
                </Content>
            </MotionBox>

        </SecureWithLogin>

    )
}
