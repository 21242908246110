import './loaderStyles.scss';

import { Spin, theme } from 'antd';

export const Loader = () => {
  const {
    token: { borderRadius },
  } = theme.useToken();

  return (
    <div className="loader-container" style={{ borderRadius }}>
      <Spin tip="Loading">
        <div className="content" />
      </Spin>
    </div>
  );
};