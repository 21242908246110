import 'react-json-pretty/themes/monikai.css';
import './../../assets/scss/ProfileDetails.scss';

import { Button, Card, Col, Flex, Row } from 'antd';
import { useContext, useEffect, useState } from "react";

import { CodeOutlined } from '@ant-design/icons';
import { EnvContext } from '../../contexts/EnvironmentContext';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ReactJson from '@microlink/react-json-view';
import ShowError from '../Layout/ShowError';
import { callBackEnd } from "../../utils/backEndUtils";
import { useParams } from "react-router-dom";

export default function ProfileDetails() {
    const { profileId } = useParams();
    const env = useContext(EnvContext);


    const [profileDetails, setProfileDetails] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const [showJson, setShowJson] = useState<boolean>(false);

    useEffect(() => {
        async function getProfileDetails() {
            try {
                setLoading(true);

                const response = await callBackEnd(env, 'GET', `api/v1/core/repository/records/${profileId}`);
                setProfileDetails(response);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getProfileDetails();
    }, [profileId, env]);

    const toggleShowJson = (e: any) => {
        e.preventDefault();
        setShowJson(!showJson);
    }

    const renderStringProp = (label: string, prop: string) => {
        if (!prop) return null;
        return (
            <>
                {label !== '' && <><span className="label">{label}</span>{': '}</>}
                {prop}
            </>
        )
    }

    return (
        <>
            <PageHeader
                title='Profile Details'
                subtitle='Portal'
                breadcrumbs={[
                    { title: <span>Home</span>, href: '/', },
                    { title: <span>Profile Details</span> }]}
            />
            <div className="jsonCode profileDetails">
                {!loading && error === null && profileDetails !== null ?

                    <Card title={<>Profile: {profileId}</>} className="profileDetailsCard" extra={<Button type="primary" onClick={toggleShowJson} icon={<CodeOutlined />}>{showJson ? "Hide" : "Show"} JSON Object</Button>}>
                        {profileDetails.data.map((item: any, index: number) => {
                            return (
                                <Flex vertical gap='middle' key={index}>
                                    {showJson &&
                                        <MotionBox>
                                            <div className="jsonObject">
                                                <ReactJson src={profileDetails} indentWidth={2} />
                                            </div>
                                        </MotionBox>
                                    }

                                    <Row gutter={[16, 16]}>

                                        {item?.identity &&
                                            <Col
                                                xs={{ flex: '100%' }}
                                                sm={{ flex: '100%' }}
                                                md={{ flex: '50%' }}
                                                lg={{ flex: '50%' }}
                                                xl={{ flex: '50%' }}
                                            >

                                                <Card type='inner' title="Identity">
                                                    {item?.identity?.gender && <p>{renderStringProp('Gender', item?.identity?.gender)}</p>}
                                                    {item?.identity?.name &&
                                                        <>
                                                            <h3>Name</h3>
                                                            <ul>
                                                                {item?.identity?.name?.type && <li>{renderStringProp('Type', item?.identity?.name?.type)}</li>}
                                                                {item?.identity?.name?.quality && <li>{renderStringProp('Quality', item?.identity?.name?.quality)}</li>}
                                                                {item?.identity?.name?.name && <li>{renderStringProp('Name', item?.identity?.name?.name)}</li>}
                                                                {item?.identity?.name?.firstName && <li>{renderStringProp('First Name', item?.identity?.name?.firstName)}</li>}
                                                                {item?.identity?.name?.middleName && <li>{renderStringProp('Middle Name', item?.identity?.name?.middleName)}</li>}
                                                                {item?.identity?.name?.affinity && <li>{renderStringProp('Affinity', item?.identity?.name?.affinity)}</li>}
                                                            </ul>
                                                        </>
                                                    }
                                                    {item?.identity?.aliases &&
                                                        <>
                                                            <h3>Aliases</h3>
                                                            {item?.identity?.aliases?.map((alias: any, index: number) => {
                                                                return (<ul className="listWithMargin" key={index}>
                                                                    {item?.identity?.name?.type && <li>{renderStringProp('Type', alias?.type)}</li>}
                                                                    {alias?.quality && <li>{renderStringProp('Quality', alias?.quality)}</li>}
                                                                    {alias?.name && <li>{renderStringProp('Name', alias?.name)}</li>}
                                                                    {alias?.firstName && <li>{renderStringProp('First Name', alias?.firstName)}</li>}
                                                                    {alias?.middleName && <li>{renderStringProp('Middle Name', alias?.middleName)}</li>}
                                                                    {alias?.affinity && <li>{renderStringProp('Affinity', alias?.affinity)}</li>}
                                                                </ul>)
                                                            })}
                                                        </>
                                                    }

                                                    {item?.identity?.birth &&
                                                        <>
                                                            {item?.identity?.birth?.dates &&
                                                                <>
                                                                    <h3>Date of birth</h3>
                                                                    {item?.identity?.birth?.dates?.map((date: any, index: number) => {
                                                                        return (<ul key={index}>
                                                                            {date?.year && <li>{renderStringProp('Year', date?.year)}</li>}
                                                                            {date?.month && <li>{renderStringProp('Month', date?.month)}</li>}
                                                                            {date?.day && <li>{renderStringProp('Day', date?.day)}</li>}
                                                                        </ul>)
                                                                    }
                                                                    )}
                                                                </>
                                                            }
                                                            {item?.identity?.birth?.dates &&
                                                                <>
                                                                    <h3>Place of birth</h3>
                                                                    {item?.identity?.birth?.places?.map((place: any, index: number) => {
                                                                        return (<ul key={index}>
                                                                            {place?.state && <li>{renderStringProp('State', place?.state)}</li>}
                                                                            {place?.country && <li>{renderStringProp('Country', place?.country)}</li>}
                                                                        </ul>)
                                                                    }
                                                                    )}
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                </Card>
                                            </Col>
                                        }
                                        {item?.publication &&
                                            <Col
                                                xs={{ flex: '100%' }}
                                                sm={{ flex: '100%' }}
                                                md={{ flex: '50%' }}
                                                lg={{ flex: '50%' }}
                                                xl={{ flex: '50%' }}
                                            >
                                                <Card type='inner' title="Publications">
                                                    {item?.publication?.localId && <p>{renderStringProp('Local Id', item?.publication?.localId)}</p>}
                                                    {item?.publication?.listId && <p>{renderStringProp('List Id', item?.publication?.listId)}</p>}
                                                    {item?.publication?.checksum && <p>{renderStringProp('Checksum', item?.publication?.checksum)}</p>}
                                                    {item?.publication?.coreChecksum && <p>{renderStringProp('Core Checksum', item?.publication?.coreChecksum)}</p>}

                                                    {item?.publication?.listVersion &&
                                                        <>
                                                            <h3>List Version</h3>
                                                            <ul>
                                                                {item?.publication?.listVersion?.year && <li>{renderStringProp('Year', item?.publication?.listVersion?.year)}</li>}
                                                                {item?.publication?.listVersion?.month && <li>{renderStringProp('Month', item?.publication?.listVersion?.month)}</li>}
                                                                {item?.publication?.listVersion?.day && <li>{renderStringProp('Day', item?.publication?.listVersion?.day)}</li>}
                                                            </ul>
                                                        </>
                                                    }
                                                    {item?.publication?.updated &&
                                                        <>
                                                            <h3>Updated</h3>
                                                            <ul>
                                                                {item?.publication?.updated?.year && <li>{renderStringProp('Year', item?.publication?.updated?.year)}</li>}
                                                                {item?.publication?.updated?.month && <li>{renderStringProp('Month', item?.publication?.updated?.month)}</li>}
                                                                {item?.publication?.updated?.day && <li>{renderStringProp('Day', item?.publication?.updated?.day)}</li>}
                                                            </ul>
                                                        </>
                                                    }
                                                    {item?.publication?.action && <p>{renderStringProp('Action', item?.publication?.action)}</p>}
                                                    {item?.publication?.sources &&
                                                        <>
                                                            <h3>Sources</h3>
                                                            <ul>
                                                                {item?.publication?.sources?.map((source: any, index: number) => {
                                                                    return (<li key={index}>
                                                                        {source?.publicationDate &&
                                                                            <>
                                                                                <span className="label">Publication Date</span>
                                                                                <ul>
                                                                                    {source?.publicationDate?.year && <li>{renderStringProp('Year', source?.publicationDate?.year)}</li>}
                                                                                    {source?.publicationDate?.month && <li>{renderStringProp('Month', source?.publicationDate?.month)}</li>}
                                                                                    {source?.publicationDate?.day && <li>{renderStringProp('Day', source?.publicationDate?.day)}</li>}
                                                                                </ul>
                                                                            </>
                                                                        }

                                                                        {source?.source && <p>{renderStringProp('Source', source?.source)}</p>}
                                                                        {source?.url && <p>{renderStringProp('URL', source?.url)}</p>}
                                                                    </li>)
                                                                })}
                                                            </ul>
                                                        </>
                                                    }

                                                </Card>
                                            </Col>
                                        }
                                        {item?.occupations &&
                                            <Col
                                                xs={{ flex: '100%' }}
                                                sm={{ flex: '100%' }}
                                                md={{ flex: '50%' }}
                                                lg={{ flex: '50%' }}
                                                xl={{ flex: '50%' }}
                                            >
                                                <Card type='inner' title="Occupations">
                                                    <ul>
                                                        {item?.occupations?.map((occupation: any, index: number) => {
                                                            return (<li key={index}>{renderStringProp('', occupation?.description)}</li>)
                                                        })}
                                                    </ul>
                                                </Card>
                                            </Col>
                                        }
                                        {item?.classification &&
                                            <Col
                                                xs={{ flex: '100%' }}
                                                sm={{ flex: '100%' }}
                                                md={{ flex: '50%' }}
                                                lg={{ flex: '50%' }}
                                                xl={{ flex: '50%' }}
                                            >
                                                <Card type='inner' title="Classification">
                                                    {item?.classification?.type && <p>{renderStringProp('Type', item?.classification?.type)}</p>}

                                                    {item?.classification?.categories &&
                                                        <>
                                                            <h3>Categories</h3>
                                                            <ul>
                                                                {item?.classification?.categories?.map((category: any, index: number) => {
                                                                    return (<li key={index}>{category}</li>)
                                                                })}
                                                            </ul>
                                                        </>
                                                    }
                                                    {item?.classification?.tags &&
                                                        <>
                                                            <h3>Tags</h3>
                                                            <ul>
                                                                {item?.classification?.tags?.map((tag: any, index: number) => {
                                                                    return (<li key={index}>{tag}</li>)
                                                                })}
                                                            </ul>
                                                        </>
                                                    }
                                                </Card>
                                            </Col>
                                        }


                                        {item?.sanctions &&
                                            <Col
                                                xs={{ flex: '100%' }}
                                                sm={{ flex: '100%' }}
                                                md={{ flex: '50%' }}
                                                lg={{ flex: '50%' }}
                                                xl={{ flex: '50%' }}
                                            >
                                                <Card type='inner' title="Sanctions">
                                                    {item?.sanctions?.map((sanction: any, index: number) => {
                                                        return (<ul key={index}>
                                                            {sanction?.list && <li>{renderStringProp('List', sanction?.list)}</li>}
                                                            {sanction?.issuerCode && <li>{renderStringProp('Issuer Code', sanction?.issuerCode)}</li>}
                                                            {sanction?.issuerCountry && <li>{renderStringProp('Issuer Country', sanction?.issuerCountry)}</li>}
                                                        </ul>)
                                                    })}
                                                </Card>
                                            </Col>
                                        }
                                        <Col
                                            xs={{ flex: '100%' }}
                                            sm={{ flex: '100%' }}
                                            md={{ flex: '50%' }}
                                            lg={{ flex: '50%' }}
                                            xl={{ flex: '50%' }}
                                        >
                                            <Card type='inner' title="Additional Information">
                                                {item._id && <p>{renderStringProp('ID', item._id)}</p>}
                                                {item._schema && <p>{renderStringProp('Schema', item._schema)}</p>}
                                                {item.notes && <p>{renderStringProp('Notes', item.notes)}</p>}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Flex>

                            )
                        }
                        )
                        }
                    </Card>
                    :
                    <>
                        {loading && <LoadingSkeleton />}
                        {error && <ShowError error={error.toString()} />}
                    </>
                }
            </div>


        </>
    )
}
